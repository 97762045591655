import React, { useEffect } from 'react'
import icon5 from "../../Assets/Images/icon-5-light.svg";
import icon6 from "../../Assets/Images/icon-6-light.svg";
import icon7 from "../../Assets/Images/icon-7-light.svg";

import AOS from 'aos';
import 'aos/dist/aos.css';
const Dcode = ({ decode, decodeCard }) => {

    console.log("decodeCard ====", decodeCard)

    useEffect(() => {
        AOS.init();
    }, [])
    return (
        <>
            <section className='light-gradient'>

                <div className="container py-5 ">

                    {decode ? (
                        <div className="row my-5 justify-content-center">
                            <div className="col-9">
                                <h1 className='fw-700 text-center'>
                                    {decode?.title}
                                    {/* Focus on <span className='text-blue font-alegreya italic'> getting things </span> done with DCode */}
                                </h1>
                                <p className='s-22 text-center mt-4'>
                                    {decode?.description}
                                    {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis finibus mi id elit gravida, quis tincidunt purus fringilla. Aenean convallis a neque non pellentesque. */}
                                </p>
                            </div>
                        </div>
                    ) : ""}


                    <div className="row ">

                        {
                            Array.isArray(decodeCard) && (
                                decodeCard?.map((items, index) => {

                                    return (
                                        <>
                                            <div key={index} className="col-md-4 text-center" data-aos-duration="1200" data-aos="zoom-in"
                                                data-aos-anchor-placement="bottom-bottom">
                                                <div className='rounded-circle round m-auto ' >

                                                    <img
                                                        src={items?.image}
                                                        alt="" width="70px" height="70px" className='p-1' />

                                                </div>
                                                <h4 className='fw-700 text-dark mt-3'>
                                                    {items?.title}
                                                </h4>
                                                <p className='s-16'>
                                                    {items?.description}
                                                </p>
                                            </div>
                                        </>
                                    )

                                })
                            )
                        }
                        {/* <div className="col-md-4 text-center" data-aos-duration="1200" data-aos="zoom-in"
                            data-aos-anchor-placement="bottom-bottom">
                            <div className='rounded-circle round m-auto ' >

                                <img src={icon5} alt="" width="70px" height="70px" className='p-1' />

                            </div>
                            <h4 className='fw-700 text-dark mt-3'>Improve Efficiency</h4>
                            <p className='s-16'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean sodales</p>
                        </div>

                        <div className="col-md-4 text-center" data-aos-duration="1000" data-aos="zoom-in">
                            <div className='rounded-circle round m-auto' >

                                <img src={icon6} alt="" width="70px" height="70px" className='p-1' />

                            </div>
                            <h4 className='fw-700 text-dark mt-3'>Improve Quality</h4>
                            <p className='s-16'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean sodales</p>
                        </div>

                        <div className="col-md-4 text-center" data-aos-duration="1400" data-aos="zoom-in">
                            <div className='rounded-circle round m-auto' >

                                <img src={icon7} alt="" width="70px" height="70px" className='p-1' />

                            </div>
                            <h4 className='fw-700 text-dark mt-3'>Improve Accountability</h4>
                            <p className='s-16'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean sodales</p>
                        </div> */}


                    </div>

                    {decode ? (
                        <div className="row">
                            <div className="col-12 text-center mt-4">
                                <button className="btn-theme rounded">
                                    {decode?.button_title}
                                </button>
                            </div>
                        </div>
                    ) :
                        ""}

                </div>
            </section>

        </>
    )
}

export default Dcode