import React, { useEffect } from 'react'
import { visitorInsert } from '../../services/api'
import usePost from '../../hooks/usePost'

const TotalVisitor = () => {

    const [vizitorRequest, loading, error] = usePost()



    const GenerateRandomId = () => {
        const randomNumber = Math.floor(Math.random() * 10000000000)
        const getId = localStorage.getItem("randomId")

        if (!getId) {
            localStorage.setItem("randomId", randomNumber)
        }
        handleSubmit()
    }

    const handleSubmit = async () => {
        const id = localStorage.getItem("randomId")
        const data = {
            device_id: id
        }

        const res = await vizitorRequest(`${visitorInsert}`, data)
        console.log('result', res);
    }



    useEffect(() => {
        GenerateRandomId();
    }, [])

    return (
        <>
        </>
    )
}

export default TotalVisitor