import React from 'react'
import blog1 from "../../Assets/Images/blog1.webp";

import { Swiper, SwiperSlide } from 'swiper/react';


import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { useDispatch, useSelector } from 'react-redux';

const HomeBlog = () => {

    const dispatch = useDispatch()
    const { data, loading } = useSelector(state => state.whyUs)
    return (
        <>
            {data?.blogs?.length > 0 && (
                <div className="container">
                    <div className="row justify-content-center ">


                        <h1 className='text-center mt-5'>
                            Grow your knowledge with us
                        </h1>

                        <div className="col-md-12">
                            <div className="row">
                                <Swiper
                                    className='pb-5'
                                    modules={[Navigation, Pagination, Scrollbar, A11y]}

                                    // spaceBetween={50}
                                    spaceBetween={25}

                                    // slidesPerView={1}
                                    onSlideChange={() => console.log('slide change')}
                                    onSwiper={(swiper) => console.log(swiper)}
                                    pagination={{ clickable: true }}
                                    breakpoints={{
                                        320: {
                                            slidesPerGroup: 1,
                                            slidesPerView: 1,
                                        },

                                        // when window width is >= 576px
                                        576: {
                                            slidesPerGroup: 1,
                                            slidesPerView: 1,
                                        },
                                        // when window width is >= 768px
                                        768: {
                                            slidesPerGroup: 1,
                                            slidesPerView: 2,
                                        },
                                        // when window width is >= 1024px
                                        1024: {
                                            slidesPerView: 3,
                                            slidesPerGroup: 1,

                                        },
                                        1280: {
                                            slidesPerGroup: 1,
                                            slidesPerView: 3,
                                        },
                                    }}

                                >

                                    {
                                        Array.isArray(data?.blogs) && (
                                            data?.blogs?.map((item, i) => {
                                                return (
                                                    <SwiperSlide >
                                                        <div className="col-md-12 col-10 h-100 py-5">
                                                            <div className="card h-100 position-relative justify-content-center p-5  rounded-5 bg-light border-0">
                                                                <p className='s-12 fw-500'>BLOG</p>
                                                                <h5>
                                                                    {/* 5 Creative Solutions for Healthcare Recruiting */}
                                                                    {item?.heading}
                                                                </h5>

                                                                <p className=''>Read Blog Post</p>

                                                                <div class="position-absolute top-90 start-90 translate-middle">
                                                                    <img src={item?.image} width="100px" height="100px" className='rounded-circle' alt="" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </SwiperSlide>
                                                )
                                            })
                                        )
                                    }


                                </Swiper>
                            </div>

                        </div>


                    </div>
                </div>
            )}

        </>
    )
}

export default HomeBlog