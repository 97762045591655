import React from 'react'
import top from "../../Assets/Images/top.jpg";
const ManageWork = (manageWork) => {
    return (
        <>
            <div className="container">
                <div className="row my-5 align-items-center justify-content-center">

                    {manageWork?.manageWork ? (
                        <div className="col-lg-6 p-md-5">
                            <h1 className='fw-700'>
                                {manageWork?.manageWork?.title}
                                {/* Manage all your work from */}
                                {/* <span className='text-blue font-alegreya'> <i> One Place </i></span> */}
                            </h1>

                            <p className='s-22 text-secondary'>

                                {manageWork?.manageWork?.description}
                                {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                Aenean sodales dictum viverra. */}
                            </p>

                            <p className='text-secondary'>
                                {manageWork?.manageWork?.sub_description}

                                {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean sodales dictum viverra.
                                Nam gravida dignissim eros.
                                Vivamus congue erat ante, volutpat dictum neque dignissim eget. */}
                            </p>

                            <div className='mt-5'>
                                <button className='btn btn-theme'>
                                    {/* discover more */}
                                    {manageWork?.manageWork?.button_title}

                                </button>
                            </div>
                        </div>
                    ) : ""}


                    <div className="col-md-6 d-none d-md-block">
                        <div className='shake-x '>
                            <img
                                src={manageWork?.manageWork?.image}

                                width="100%" className='rounded-3' alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ManageWork