import React from 'react'
import Rectangle from "../../Assets/Images/Rectangle.svg";
import { useSelector } from 'react-redux';

const RequestDemo = () => {

    const { data } = useSelector((state => state.whyUs))

    return (
        <>
            <section className='light-gradient'>
                <div className="container">
                    <div className="row p-5 align-items-center">
                        <div className="col-md-7">
                            <h2>
                                {/* Request a demo */}
                                {data?.request_demo?.heading}
                            </h2>

                            <p>
                                {/* Ready to take your talent optimization strategy to the next level? Request a demo to
                                learn how SeekOut can help you recruit hard-to-find and diverse talent. */}
                                {data?.request_demo?.description}
                            </p>

                            <button className='btn-theme mt-5 rounded'>
                                {/* Request a demo */}
                                {data?.request_demo?.button}
                            </button>
                        </div>

                        <div className="col-md-5 ">
                            <img src={data?.request_demo?.image} width="100%" alt="" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default RequestDemo