import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import ChatList from "./ChatList";
import usePostWithFormData from "../../hooks/usePostWithFormData";
import {createUser, sendMessage} from "../../services/api";
import { useDispatch, useSelector } from "react-redux";
import { saveUser } from "../../Redux/Slices/userSlice";
import usePost from "../../hooks/usePost";

const ChatBox = ({closeChat}) => {
  let chatId = useSelector((state) => state?.user?.data?.id);
  const [createRequest, isLoading] = usePostWithFormData();
  const [sendMessageRequest, isLoadingMessage] = usePost();
  const dispatch = useDispatch();

  const handleCreate = async (e) => {
    e.preventDefault();
    const data = await createRequest(`${createUser}`, e.currentTarget);
    if (data?.status === "success") {
      dispatch(saveUser(data?.data));
    }
  };

  const handleSendMessage = async (event) => {
    event.preventDefault();
    if (event.target.message.value.trim()){
      let data = {
        user_id: chatId,
        sender_id: chatId,
        reciver_id: 1,
        message: event.target.message.value,
      }
      const res = await sendMessageRequest(`${sendMessage}`, data);
      if(res?.status == "success"){
        // setChatList(res?.data);
      }
      event.target.message.value = '';
    }
  }

  console.log("chatId", chatId);

  return (
    <>
      <div className="card chatBox" style={{ zIndex: "9999" }}>
        <div className="card-header position-relative bg-theme py-3">
          <div className="position-absolute top-0 start-100 translate-middle">
            <button
              // onClick={handleCloseChat}
              className="btn btn-danger rounded-circle d-flex align-items-center justify-content-center p-0"
              style={{ width: "30px", height: "30px" }}
            >
              <i className="fa-solid fa-circle-xmark s-14 d-block" onClick={closeChat}/>
            </button>
          </div>
          <p className="text-center text-dark mb-0">
            We are live and ready to chat with you
          </p>
        </div>

        {chatId ? (
          <>
            <ChatList id={chatId}/>

            <div className="card-footer bg-transparent">
              <Form
                onSubmit={handleSendMessage}
                className="row align-items-center"
              >
                <div className="col px-1">
                  <input
                    className="form-control border-0 p-2 w-100"
                    placeholder="Write a message..."
                    type="text"
                    name="message"
                    disabled={isLoadingMessage}
                  />
                </div>
                <div className="col-auto px-1">
                  <button
                    className="btn border-0"
                    type="submit"
                    disabled={isLoadingMessage}
                  >
                    <i className="fa-solid fa-paper-plane d-block s-20" />
                  </button>
                </div>
              </Form>
            </div>
          </>
        ) : (
          <>
            <Card>
              <Card.Body>
                <Form onSubmit={handleCreate}>
                  Please Enter Your Email
                  <input
                    type="email"
                    name="email"
                    className="form-control mb-3"
                    placeholder="Enter your Email"
                    required={true}
                  />
                  Please Enter Your Name
                  <input
                    type="text"
                    name="name"
                    className="form-control mb-3"
                    placeholder="Enter your Name"
                    required={true}
                  />
                  <button
                    type="submit"
                    className="btn btn-primary w-100"
                    // disabled={isFetching}
                  >
                    {isLoading ? (
                      <span className="spinner-border spinner-border-sm" />
                    ) : (
                      "Let's Start Chat"
                    )}
                  </button>
                </Form>
              </Card.Body>
            </Card>
          </>
        )}
      </div>
    </>
  );
};

export default ChatBox;
