import React from 'react'
import whyus from "../../Assets/Images/whyus.webp";
import { useSelector } from 'react-redux';

const WhyUsScreen = () => {

    const { data } = useSelector((state => state.whyUs))


    return (
        <>
            <section className='light-gradient py-5' style={{marginTop:"5rem"}}>

                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h5 className='text-blue'>
                                {/* WHY SEEKOUT? */}
                                {data?.banner?.sub_heading}
                            </h5>
                            <h1 className='text-blue fw-600'>
                                {/* Know your talent to grow your talent */}
                                {data?.banner?.heading}

                            </h1>

                            <p className='text-blue fw-500'>
                                {/* After years of uncertainty, one thing is clear—HR leaders need a flexible talent strategy. Navigate any change with the most powerful people data at your fingertips. Recruit the talent you need and help employees navigate their careers with the most robust talent optimization platform. */}
                                {data?.banner?.description}

                            </p>

                            <button className='btn-theme rounded'>
                                {/* Request a demo */}
                                {data?.banner?.button_title}

                            </button>
                        </div>

                        <div className="col-md-6">
                            <img src={whyus} width="100%" className='p-5' alt="" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default WhyUsScreen