import React from 'react'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import blog1 from "../../Assets/Images/blog1.webp";
import blog2 from "../../Assets/Images/blog2.webp";
import blog3 from "../../Assets/Images/blog3.webp";
import Pagination from 'react-bootstrap/Pagination';
import { useDispatch } from 'react-redux';
import { nextPage } from '../../Redux/Slices/eventSlice';


const EventScreen = ({ events }) => {

  const dispatch = useDispatch()

  const goAhead = (url) => {
    if (url !== null) {
      dispatch(nextPage(url))
    }
  }

  return (
    <>
      <div className="container">
        <div className="row py-5">

          {Array.isArray(events?.data) && (
            events?.data?.map((item, i) => {
              return (
                <>
                  <div className="col-md-4 my-4">


                    <Card className='h-100 rounded-4 overflow-hidden'>
                      <Card.Img variant="top" src={item?.image} width="100" height="200" style={{ objectFit: "cover" }} />
                      <Card.Body>
                        <Card.Text className='s-14'>
                          Events
                        </Card.Text>
                        <Card.Title>
                          {/* Some quick example text to build on the card title and make up the
                          bulk of the card's content. */}
                          {item?.heading}
                        </Card.Title>

                        <Card.Text className='s-16'>
                          View Events
                        </Card.Text>
                        {/* <Button className='btn-subscribe'>View Event</Button> */}
                      </Card.Body>
                    </Card>

                  </div>
                </>
              )
            })

          )}

          <div className="col-md-12 text-center">

            <Pagination className='justify-content-center'>
              {
                Array.isArray(events?.links) && (
                  events?.links?.map((item) => {
                    if (item?.label == "&laquo; Previous") {
                      return <Pagination.Prev onClick={() => { goAhead(item?.url) }} />
                    } else if (item?.label == "Next &raquo;") {
                      return <Pagination.Next onClick={() => { goAhead(item?.url) }} />
                    } else {
                      return <Pagination.Item active={item?.active} onClick={() => { goAhead(item?.url) }} >{item?.label}</Pagination.Item>
                    }
                  })
                )
              }
              {/* <Pagination.Ellipsis /> */}
            </Pagination>

          </div>


        </div>
      </div>
    </>
  )
}

export default EventScreen