import React, { useEffect } from 'react'
import EventScreen from '../Components/Events/EventScreen'
import EventsBanner from '../Components/Events/EventsBanner'
import ScrollToTop from '../Components/ScrollToTop'
import { useDispatch, useSelector } from 'react-redux'
import { getEvents, nextPage } from '../Redux/Slices/eventSlice'
import Loader from '../Components/Loader/Loader'

const Events = () => {

  const dispatch = useDispatch()
  const { data, loading } = useSelector(state => state.event)

  useEffect(() => {
    dispatch(getEvents())

  }, [])

  if (loading) return (<Loader />)

  return (
    <>
      <ScrollToTop />
      <EventsBanner banner={data?.banner} />
      <EventScreen events={data?.events}/>
    </>
  )
}

export default Events