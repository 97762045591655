import React, { useEffect, useState } from 'react'
import { Outlet, Link } from "react-router-dom";
import FooterScreen from '../Footer/FooterScreen';
import NavbarScreen from "../Navbar/NavbarScreen";
import useGet from '../../hooks/useGet';
import { webHeaderFooterView } from '../../services/api';
import Loader from '../Loader/Loader';
import Chat from "../chat";
import TotalVisitor from './TotalVisitor';


const LayoutScreen = () => {

  const [data, loading, error] = useGet(`${webHeaderFooterView}`)



  const [header, setHeader] = useState([])
  const [footerContent, setFooterContent] = useState(null)
  const [socialIcons, setSocialIcons] = useState([])

  useEffect(() => {
    if (data) {
      setHeader(data?.header)
      setFooterContent(data?.footer_content)
      setSocialIcons(data?.social_icons)
    }
  }, [data])

  if (loading) {
    return (
      <Loader />
    )
  }

  return (
    <>
      <TotalVisitor />
      <NavbarScreen header={header} footerContent={footerContent} />

      <Outlet />

      <Chat />
      <FooterScreen footerContent={footerContent} socialIcons={socialIcons} />

    </>
  )
}

export default LayoutScreen