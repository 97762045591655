import React from 'react'
import SignInScreen from '../Components/Auth/SignInScreen'
import ScrollToTop from '../Components/ScrollToTop'

const SignIn = () => {
    return (
        <>
        <ScrollToTop />
            <SignInScreen />
        </>
    )
}

export default SignIn