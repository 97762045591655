import React from 'react'
import ContactUsScreen from '../Components/ContactUs/ContactUsScreen'
import ScrollToTop from '../Components/ScrollToTop'

const ContactUs = () => {
    return (
        <>
            <ScrollToTop />
            <ContactUsScreen />
        </>
    )
}

export default ContactUs