import React, { useEffect } from 'react'
import BlogScreen from '../Components/Blogs/BlogScreen'
import BlogsBanner from "../Components/Blogs/BlogsBanner";
import ScrollToTop from '../Components/ScrollToTop';
import { useDispatch, useSelector } from 'react-redux';
import { getBlogs } from '../Redux/Slices/blogSlice';
import Loader from '../Components/Loader/Loader';

const Blog = () => {

  const dispatch = useDispatch()
  const { data, loading } = useSelector(state => state.blog)

  useEffect(() => {
    dispatch(getBlogs())
  }, [])

  if (loading) {
    return (
      <Loader />
    )
  }
  return (
    <>
      <ScrollToTop />
      <BlogsBanner banner={data?.banner} />
      <BlogScreen blogs={data?.blogs} />
    </>
  )
}

export default Blog