import React, { useEffect, useState } from 'react'
import "./Home.css"
import { Link } from 'react-router-dom'

import { Swiper, SwiperSlide } from 'swiper/react';


import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { get, packages } from '../../services/api';
import useGet from '../../hooks/useGet';
import Loader from '../Loader/Loader';

const Pricing = () => {
    const [data, loading, error] = useGet(`${packages}`)

    // console.log("first ===== ", data?.data)

    const [changePrice, setChangePrice] = useState(true)

    if (loading) return (<Loader />)
    return (
        <>
            <div className="container" style={{ marginTop: "5rem" }}>
                <div className="row my-5">
                    <div className="col-12 mb-3 ">
                        <h1 className='fw-700 text-center' >Pricing Plans</h1>

                        <div className="row d-none justify-content-center mt-5">
                            <div className="col-auto">

                                <p className='fw-700 text-uppercase'>Monthly</p>

                            </div>
                            <div className='col-auto'>
                                <div className="form-check form-switch">
                                    <input className="form-check-input" onChange={() => setChangePrice(!changePrice)} type="checkbox" id="flexSwitchCheckDefault" />
                                </div>

                            </div>
                            <div className="col-auto">
                                <p className='fw-700 text-uppercase'>yearly</p>
                            </div>

                        </div>

                    </div>

                    <>

                        <Swiper
                            className='pb-5'
                            modules={[Navigation, Pagination, Scrollbar, A11y]}

                            // spaceBetween={50}
                            spaceBetween={20}

                            // slidesPerView={1}
                            onSlideChange={() => console.log('slide change')}
                            onSwiper={(swiper) => console.log(swiper)}
                            pagination={{ clickable: true }}
                            breakpoints={{
                                320: {
                                    slidesPerGroup: 1,
                                    slidesPerView: 1,
                                },

                                // when window width is >= 576px
                                576: {
                                    slidesPerGroup: 1,
                                    slidesPerView: 1,
                                },
                                // when window width is >= 768px
                                768: {
                                    slidesPerGroup: 1,
                                    slidesPerView: 2,
                                },
                                // when window width is >= 1024px
                                1024: {
                                    slidesPerView: 3,
                                    slidesPerGroup: 1,

                                },
                                1280: {
                                    slidesPerGroup: 1,
                                    slidesPerView: 3,
                                },
                            }}

                        >

                            {
                                data?.data?.map((item, index) => {
                                    return (
                                        <>
                                            <SwiperSlide key={index}>
                                                <div className="col-md-12 my-3 " data-aos="fade-up" data-aos-duration="1000">

                                                    <div className="card p-3 h-100 rounded-5 overflow-hidden border-0 shadow">
                                                        <div className="card-header h-100 bg-white">
                                                            <h5 className='fw-600 text-dark '>{item?.title}</h5>
                                                            {changePrice ? (
                                                                <h1 className='fw-700 text-blue'>
                                                                    ${item?.price}<span className='text-muted s-12'>/Month</span>
                                                                </h1>
                                                            ) : (
                                                                <h1 className='fw-700 text-blue'>
                                                                    $25.00 <span className='text-muted s-12'>/Year</span>
                                                                </h1>
                                                            )}
                                                        </div>

                                                        <div className="card-body h-100 p-0 border-0">
                                                            <div className="flip-card border-0">

                                                                <div className="flip-card-inner">

                                                                    <div className="flip-card-front h-100 ">

                                                                        <ul className=''>
                                                                            <li>
                                                                                <div className="row align-items-center ">
                                                                                    <div className="col-1 p-0">
                                                                                        <i className="fa-solid fa-check text-blue s-18"></i>

                                                                                    </div>

                                                                                    <div className="col-auto text-dark fw-600">
                                                                                        Easy Customizable
                                                                                    </div>

                                                                                </div>
                                                                            </li>

                                                                            <li>
                                                                                <div className="row align-items-center ">
                                                                                    <div className="col-1 p-0">
                                                                                        <i className="fa-solid fa-check text-blue s-18"></i>
                                                                                    </div>

                                                                                    <div className="col-auto text-dark fw-600">
                                                                                        <p className='mb-0'>
                                                                                            User license limit
                                                                                            <span>
                                                                                                {" "}
                                                                                                {item?.user_limit}
                                                                                            </span>
                                                                                        </p>
                                                                                    </div>

                                                                                </div>
                                                                            </li>

                                                                            <li>
                                                                                <div className="row align-items-center ">
                                                                                    <div className="col-1 p-0">
                                                                                        <i className="fa-solid fa-check text-blue s-18"></i>

                                                                                    </div>

                                                                                    <div className="col-auto text-dark fw-600">
                                                                                        <p className='mb-0'>
                                                                                            <span>

                                                                                                Agent limit
                                                                                            </span>

                                                                                            <span>
                                                                                                {" "}{item?.agent_limit}
                                                                                            </span>

                                                                                        </p>
                                                                                    </div>

                                                                                </div>
                                                                            </li>

                                                                            <li>
                                                                                <div className="row align-items-center ">
                                                                                    <div className="col-1 p-0 ">
                                                                                        <i className="fa-solid fa-xmark text-danger s-18"></i>

                                                                                    </div>

                                                                                    <div className="col-auto text-muted fw-600">
                                                                                        <p className='mb-0'>
                                                                                            Support Service
                                                                                        </p>
                                                                                    </div>

                                                                                </div>
                                                                            </li>

                                                                            <li>
                                                                                <div className="row align-items-center ">
                                                                                    <div className="col-1 p-0">
                                                                                        <i className="fa-solid fa-xmark text-danger s-18"></i>

                                                                                    </div>

                                                                                    <div className="col-auto text-muted fw-600">
                                                                                        <p className='mb-0'>
                                                                                            Maintenance
                                                                                        </p>
                                                                                    </div>

                                                                                </div>
                                                                            </li>

                                                                            <li>
                                                                                <div className="row align-items-center ">
                                                                                    <div className="col-1 p-0">
                                                                                        <i className="fa-solid fa-xmark text-danger s-18"></i>

                                                                                    </div>

                                                                                    <div className="col-auto text-muted fw-600">
                                                                                        <p className='mb-0'>
                                                                                            Reports & Billing
                                                                                        </p>
                                                                                    </div>

                                                                                </div>
                                                                            </li>


                                                                        </ul>
                                                                    </div>

                                                                    <div className="flip-card-back p-4 h-100">
                                                                        <p>{item?.description}</p>

                                                                    </div>
                                                                </div>
                                                            </div>



                                                        </div>


                                                        <div className="card-footer border-0 bg-white p-3 text-center ">
                                                            <Link to={`/sign-up/${item?.faker_id}`} className='btn-subscribe rounded px-5 '>
                                                                Subscribe
                                                            </Link>
                                                        </div>


                                                    </div>
                                                </div>
                                            </SwiperSlide>

                                        </>
                                    )
                                })

                            }

                            {/* <SwiperSlide>
                                <div className="col-md-12 my-3" data-aos="fade-up" data-aos-duration="2000">

                                    <div className="card h-100 p-3 rounded-5 overflow-hidden border-0 shadow">
                                        <div className="card-header h-100 bg-white">
                                            <h5 className='fw-600 text-dark '>PRO</h5>
                                            {changePrice ? (
                                                <h1 className='fw-700 text-blue '>
                                                    $10.00 <span className='text-muted s-12'>/Month</span>
                                                </h1>
                                            ) : (
                                                <h1 className='fw-700 text-blue'>
                                                    $99.99 <span className='text-muted s-12'>/Year</span>
                                                </h1>
                                            )}
                                        </div>

                                        <div className="card-body p-0 h-100 border-0">
                                            <div className="flip-card border-0">

                                                <div className="flip-card-inner">

                                                    <div className="flip-card-front h-100">

                                                        <ul className=''>
                                                            <li>
                                                                <div className="row align-items-center ">
                                                                    <div className="col-1 p-0">
                                                                        <i className="fa-solid fa-check text-blue s-18"></i>

                                                                    </div>

                                                                    <div className="col-auto text-dark fw-600">
                                                                        Easy Customizable
                                                                    </div>

                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className="row align-items-center ">
                                                                    <div className="col-1 p-0">
                                                                        <i className="fa-solid fa-check text-blue s-18"></i>
                                                                    </div>

                                                                    <div className="col-auto text-dark fw-600">
                                                                        <p className='mb-0'>
                                                                            User license limit 1000
                                                                        </p>
                                                                    </div>

                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className="row align-items-center ">
                                                                    <div className="col-1 p-0">
                                                                        <i className="fa-solid fa-check text-blue s-18"></i>

                                                                    </div>

                                                                    <div className="col-auto text-dark fw-600">
                                                                        <p className='mb-0'>
                                                                            Agent limit 100
                                                                        </p>
                                                                    </div>

                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className="row align-items-center ">
                                                                    <div className="col-1 p-0 ">
                                                                        <i className="fa-solid fa-check text-blue s-18"></i>


                                                                    </div>

                                                                    <div className="col-auto text-dark fw-600">
                                                                        <p className='mb-0'>
                                                                            Support Service
                                                                        </p>
                                                                    </div>

                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className="row align-items-center ">
                                                                    <div className="col-1 p-0">
                                                                        <i className="fa-solid fa-check text-blue s-18"></i>


                                                                    </div>

                                                                    <div className="col-auto text-dark fw-600">
                                                                        <p className='mb-0'>
                                                                            Maintenance
                                                                        </p>
                                                                    </div>

                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className="row align-items-center ">
                                                                    <div className="col-1 p-0">
                                                                        <i className="fa-solid fa-xmark text-danger s-18"></i>

                                                                    </div>

                                                                    <div className="col-auto text-muted fw-600">
                                                                        <p className='mb-0'>
                                                                            Reports & Billing
                                                                        </p>
                                                                    </div>

                                                                </div>
                                                            </li>


                                                        </ul>
                                                    </div>

                                                    <div className="flip-card-back h-100 p-4">
                                                        <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reiciendis nobis
                                                            nisi perferendis nihil fugiat maiores temporibus laudantium magni earum ipsum.
                                                            Cum quae neque natus amet ullam aliquid ratione, possimus fuga.</p>

                                                    </div>
                                                </div>
                                            </div>



                                        </div>


                                        <div className="card-footer border-0 bg-white p-3 text-center ">
                                            <Link to="/sign-up/Gy" className='btn-subscribe rounded px-5 '>
                                                Subscribe
                                            </Link>
                                        </div>

                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className="col-md-12 my-3" data-aos="fade-up" data-aos-duration="2000">

                                    <div className="card h-100 p-3 rounded-5 overflow-hidden border-0 shadow">
                                        <div className="card-header h-100 bg-white">
                                            <h5 className='fw-600 text-dark '>PRO</h5>
                                            {changePrice ? (
                                                <h1 className='fw-700 text-blue '>
                                                    $10.00 <span className='text-muted s-12'>/Month</span>
                                                </h1>
                                            ) : (
                                                <h1 className='fw-700 text-blue'>
                                                    $99.99 <span className='text-muted s-12'>/Year</span>
                                                </h1>
                                            )}
                                        </div>

                                        <div className="card-body p-0 h-100 border-0">
                                            <div className="flip-card border-0">

                                                <div className="flip-card-inner">

                                                    <div className="flip-card-front h-100">

                                                        <ul className=''>
                                                            <li>
                                                                <div className="row align-items-center ">
                                                                    <div className="col-1 p-0">
                                                                        <i className="fa-solid fa-check text-blue s-18"></i>

                                                                    </div>

                                                                    <div className="col-auto text-dark fw-600">
                                                                        Easy Customizable
                                                                    </div>

                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className="row align-items-center ">
                                                                    <div className="col-1 p-0">
                                                                        <i className="fa-solid fa-check text-blue s-18"></i>
                                                                    </div>

                                                                    <div className="col-auto text-dark fw-600">
                                                                        <p className='mb-0'>
                                                                            User license limit 1000
                                                                        </p>
                                                                    </div>

                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className="row align-items-center ">
                                                                    <div className="col-1 p-0">
                                                                        <i className="fa-solid fa-check text-blue s-18"></i>

                                                                    </div>

                                                                    <div className="col-auto text-dark fw-600">
                                                                        <p className='mb-0'>
                                                                            Agent limit 100
                                                                        </p>
                                                                    </div>

                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className="row align-items-center ">
                                                                    <div className="col-1 p-0 ">
                                                                        <i className="fa-solid fa-check text-blue s-18"></i>


                                                                    </div>

                                                                    <div className="col-auto text-dark fw-600">
                                                                        <p className='mb-0'>
                                                                            Support Service
                                                                        </p>
                                                                    </div>

                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className="row align-items-center ">
                                                                    <div className="col-1 p-0">
                                                                        <i className="fa-solid fa-check text-blue s-18"></i>


                                                                    </div>

                                                                    <div className="col-auto text-dark fw-600">
                                                                        <p className='mb-0'>
                                                                            Maintenance
                                                                        </p>
                                                                    </div>

                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className="row align-items-center ">
                                                                    <div className="col-1 p-0">
                                                                        <i className="fa-solid fa-xmark text-danger s-18"></i>

                                                                    </div>

                                                                    <div className="col-auto text-muted fw-600">
                                                                        <p className='mb-0'>
                                                                            Reports & Billing
                                                                        </p>
                                                                    </div>

                                                                </div>
                                                            </li>


                                                        </ul>
                                                    </div>

                                                    <div className="flip-card-back h-100 p-4">
                                                        <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reiciendis nobis
                                                            nisi perferendis nihil fugiat maiores temporibus laudantium magni earum ipsum.
                                                            Cum quae neque natus amet ullam aliquid ratione, possimus fuga.</p>

                                                    </div>
                                                </div>
                                            </div>



                                        </div>


                                        <div className="card-footer border-0 bg-white p-3 text-center ">
                                            <Link to="/sign-up/Gy" className='btn-subscribe rounded px-5 '>
                                                Subscribe
                                            </Link>
                                        </div>

                                    </div>
                                </div>
                            </SwiperSlide> */}

                        </Swiper>






                    </>

                    {/*{changePrice && (
                            <>
                                <div className="col-md-4 mb-3" data-aos="fade-up" data-aos-duration="1000">

                                    <div className="card p-3 h-100 rounded-5 overflow-hidden border-0 shadow">
                                        <div className="card-header h-100 bg-white">
                                            <h5 className='fw-600 text-dark '>FREE</h5>
                                            {changePrice ? (
                                                <h1 className='fw-700 text-blue'>
                                                    $0.00 <span className='text-muted s-12'>/Month</span>
                                                </h1>
                                            ) : (
                                                <h1 className='fw-700 text-blue'>
                                                    $25.00 <span className='text-muted s-12'>/Year</span>
                                                </h1>
                                            )}
                                        </div>

                                        <div className="card-body h-100 p-0 border-0">
                                            <div className="flip-card border-0">

                                                <div className="flip-card-inner">

                                                    <div className="flip-card-front h-100 ">

                                                        <ul className=''>
                                                            <li>
                                                                <div className="row align-items-center ">
                                                                    <div className="col-1 p-0">
                                                                        <i className="fa-solid fa-check text-blue s-18"></i>

                                                                    </div>

                                                                    <div className="col-auto text-dark fw-600">
                                                                        Easy Customizable
                                                                    </div>

                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className="row align-items-center ">
                                                                    <div className="col-1 p-0">
                                                                        <i className="fa-solid fa-check text-blue s-18"></i>
                                                                    </div>

                                                                    <div className="col-auto text-dark fw-600">
                                                                        <p className='mb-0'>
                                                                            User license limit 1000
                                                                        </p>
                                                                    </div>

                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className="row align-items-center ">
                                                                    <div className="col-1 p-0">
                                                                        <i className="fa-solid fa-check text-blue s-18"></i>

                                                                    </div>

                                                                    <div className="col-auto text-dark fw-600">
                                                                        <p className='mb-0'>
                                                                            Agent limit 100
                                                                        </p>
                                                                    </div>

                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className="row align-items-center ">
                                                                    <div className="col-1 p-0 ">
                                                                        <i className="fa-solid fa-xmark text-danger s-18"></i>

                                                                    </div>

                                                                    <div className="col-auto text-muted fw-600">
                                                                        <p className='mb-0'>
                                                                            Support Service
                                                                        </p>
                                                                    </div>

                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className="row align-items-center ">
                                                                    <div className="col-1 p-0">
                                                                        <i className="fa-solid fa-xmark text-danger s-18"></i>

                                                                    </div>

                                                                    <div className="col-auto text-muted fw-600">
                                                                        <p className='mb-0'>
                                                                            Maintenance
                                                                        </p>
                                                                    </div>

                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className="row align-items-center ">
                                                                    <div className="col-1 p-0">
                                                                        <i className="fa-solid fa-xmark text-danger s-18"></i>

                                                                    </div>

                                                                    <div className="col-auto text-muted fw-600">
                                                                        <p className='mb-0'>
                                                                            Reports & Billing
                                                                        </p>
                                                                    </div>

                                                                </div>
                                                            </li>


                                                        </ul>
                                                    </div>

                                                    <div className="flip-card-back p-4 h-100">
                                                        <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reiciendis nobis
                                                            nisi perferendis nihil fugiat maiores temporibus laudantium magni earum ipsum.
                                                            Cum quae neque natus amet ullam aliquid ratione, possimus fuga.</p>

                                                    </div>
                                                </div>
                                            </div>



                                        </div>


                                        <div className="card-footer border-0 bg-white p-3 text-center ">
                                            <Link to="/sign-up/ro" className='btn-subscribe rounded px-5 '>
                                                Subscribe
                                            </Link>
                                        </div>


                                    </div>
                                </div>

                                <div className="col-md-4 mb-3" data-aos="fade-up" data-aos-duration="1500">

                                    <div className="card h-100 rounded-5 p-3 overflow-hidden border-0 shadow">
                                        <div className="card-header h-100 bg-white">
                                            <h5 className='fw-600 text-dark '>BASIC</h5>
                                            <h1 className='fw-700 text-blue '>
                                                {changePrice ? (
                                                    <h1 className='fw-700 text-blue'>
                                                        $5.00 <span className='text-muted s-12'>/Month</span>
                                                    </h1>
                                                ) : (
                                                    <h1 className='fw-700 text-blue'>
                                                        $89.00 <span className='text-muted s-12'>/Year</span>
                                                    </h1>
                                                )}

                                            </h1>
                                        </div>

                                        <div className="card-body  border-0 p-0 h-100 border-0">
                                            <div className="flip-card border-0">

                                                <div className="flip-card-inner">

                                                    <div className="flip-card-front h-100">

                                                        <ul className=''>
                                                            <li>
                                                                <div className="row align-items-center ">
                                                                    <div className="col-1 p-0">
                                                                        <i className="fa-solid fa-check text-blue s-18"></i>

                                                                    </div>

                                                                    <div className="col-auto text-dark fw-600">
                                                                        Easy Customizable
                                                                    </div>

                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className="row align-items-center ">
                                                                    <div className="col-1 p-0">
                                                                        <i className="fa-solid fa-check text-blue s-18"></i>
                                                                    </div>

                                                                    <div className="col-auto text-dark fw-600">
                                                                        <p className='mb-0'>
                                                                            User license limit 1000
                                                                        </p>
                                                                    </div>

                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className="row align-items-center ">
                                                                    <div className="col-1 p-0">
                                                                        <i className="fa-solid fa-check text-blue s-18"></i>

                                                                    </div>

                                                                    <div className="col-auto text-dark fw-600">
                                                                        <p className='mb-0'>
                                                                            Agent limit 100
                                                                        </p>
                                                                    </div>

                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className="row align-items-center ">
                                                                    <div className="col-1 p-0 ">
                                                                        <i className="fa-solid fa-check text-blue s-18"></i>


                                                                    </div>

                                                                    <div className="col-auto text-dark fw-600">
                                                                        <p className='mb-0'>
                                                                            Support Service
                                                                        </p>
                                                                    </div>

                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className="row align-items-center ">
                                                                    <div className="col-1 p-0">
                                                                        <i className="fa-solid fa-check text-blue s-18"></i>


                                                                    </div>

                                                                    <div className="col-auto text-dark fw-600">
                                                                        <p className='mb-0'>
                                                                            Maintenance
                                                                        </p>
                                                                    </div>

                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className="row align-items-center ">
                                                                    <div className="col-1 p-0">
                                                                        <i className="fa-solid fa-xmark text-danger s-18"></i>

                                                                    </div>

                                                                    <div className="col-auto text-muted fw-600">
                                                                        <p className='mb-0'>
                                                                            Reports & Billing
                                                                        </p>
                                                                    </div>

                                                                </div>
                                                            </li>


                                                        </ul>
                                                    </div>

                                                    <div className="flip-card-back p-4 h-100">
                                                        <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reiciendis nobis
                                                            nisi perferendis nihil fugiat maiores temporibus laudantium magni earum ipsum.
                                                            Cum quae neque natus amet ullam aliquid ratione, possimus fuga.</p>

                                                    </div>
                                                </div>
                                            </div>



                                        </div>


                                        <div className="card-footer border-0 bg-white p-3 text-center ">
                                            <Link to="/sign-up/VG" className='btn-subscribe rounded px-5 '>
                                                Subscribe
                                            </Link>
                                        </div>

                                        <div className="price_sale"><span className="popular">popular</span></div>


                                    </div>
                                </div>

                                <div className="col-md-4 mb-3" data-aos="fade-up" data-aos-duration="2000">

                                    <div className="card h-100 p-3 rounded-5 overflow-hidden border-0 shadow">
                                        <div className="card-header h-100 bg-white">
                                            <h5 className='fw-600 text-dark '>PRO</h5>
                                            {changePrice ? (
                                                <h1 className='fw-700 text-blue '>
                                                    $10.00 <span className='text-muted s-12'>/Month</span>
                                                </h1>
                                            ) : (
                                                <h1 className='fw-700 text-blue'>
                                                    $99.99 <span className='text-muted s-12'>/Year</span>
                                                </h1>
                                            )}
                                        </div>

                                        <div className="card-body p-0 h-100 border-0">
                                            <div className="flip-card border-0">

                                                <div className="flip-card-inner">

                                                    <div className="flip-card-front h-100">

                                                        <ul className=''>
                                                            <li>
                                                                <div className="row align-items-center ">
                                                                    <div className="col-1 p-0">
                                                                        <i className="fa-solid fa-check text-blue s-18"></i>

                                                                    </div>

                                                                    <div className="col-auto text-dark fw-600">
                                                                        Easy Customizable
                                                                    </div>

                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className="row align-items-center ">
                                                                    <div className="col-1 p-0">
                                                                        <i className="fa-solid fa-check text-blue s-18"></i>
                                                                    </div>

                                                                    <div className="col-auto text-dark fw-600">
                                                                        <p className='mb-0'>
                                                                            User license limit 1000
                                                                        </p>
                                                                    </div>

                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className="row align-items-center ">
                                                                    <div className="col-1 p-0">
                                                                        <i className="fa-solid fa-check text-blue s-18"></i>

                                                                    </div>

                                                                    <div className="col-auto text-dark fw-600">
                                                                        <p className='mb-0'>
                                                                            Agent limit 100
                                                                        </p>
                                                                    </div>

                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className="row align-items-center ">
                                                                    <div className="col-1 p-0 ">
                                                                        <i className="fa-solid fa-check text-blue s-18"></i>


                                                                    </div>

                                                                    <div className="col-auto text-dark fw-600">
                                                                        <p className='mb-0'>
                                                                            Support Service
                                                                        </p>
                                                                    </div>

                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className="row align-items-center ">
                                                                    <div className="col-1 p-0">
                                                                        <i className="fa-solid fa-check text-blue s-18"></i>


                                                                    </div>

                                                                    <div className="col-auto text-dark fw-600">
                                                                        <p className='mb-0'>
                                                                            Maintenance
                                                                        </p>
                                                                    </div>

                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className="row align-items-center ">
                                                                    <div className="col-1 p-0">
                                                                        <i className="fa-solid fa-xmark text-danger s-18"></i>

                                                                    </div>

                                                                    <div className="col-auto text-muted fw-600">
                                                                        <p className='mb-0'>
                                                                            Reports & Billing
                                                                        </p>
                                                                    </div>

                                                                </div>
                                                            </li>


                                                        </ul>
                                                    </div>

                                                    <div className="flip-card-back h-100 p-4">
                                                        <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reiciendis nobis
                                                            nisi perferendis nihil fugiat maiores temporibus laudantium magni earum ipsum.
                                                            Cum quae neque natus amet ullam aliquid ratione, possimus fuga.</p>

                                                    </div>
                                                </div>
                                            </div>



                                        </div>


                                        <div className="card-footer border-0 bg-white p-3 text-center ">
                                            <Link to="/sign-up/Gy" className='btn-subscribe rounded px-5 '>
                                                Subscribe
                                            </Link>
                                        </div>

                                    </div>
                                </div>
                            </>

                        )
                    }

                    {
                        !changePrice && (
                            <>
                                <div className="col-md-4 mb-3" data-aos="fade-up" data-aos-duration="1000">
                                    <div className="card p-3 rounded-5 overflow-hidden border-0 shadow">
                                        <div className="card-header  bg-white">
                                            <h5 className='fw-600 text-dark '>FREE</h5>
                                            <h1 className='fw-700 text-blue '>
                                                $25.00 <span className='text-muted s-12'>/Year</span>
                                            </h1>
                                        </div>

                                        <div className="card-body p-0 h-100 border-0">
                                            <div className="flip-card border-0">

                                                <div className="flip-card-inner">

                                                    <div className="flip-card-front h-100">

                                                        <ul className=''>
                                                            <li>
                                                                <div className="row align-items-center ">
                                                                    <div className="col-1 p-0">
                                                                        <i className="fa-solid fa-check text-blue s-18"></i>

                                                                    </div>

                                                                    <div className="col-auto text-dark fw-600">
                                                                        Easy Customizable
                                                                    </div>

                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className="row align-items-center ">
                                                                    <div className="col-1 p-0">
                                                                        <i className="fa-solid fa-check text-blue s-18"></i>
                                                                    </div>

                                                                    <div className="col-auto text-dark fw-600">
                                                                        <p className='mb-0'>
                                                                            User license limit 1000
                                                                        </p>
                                                                    </div>

                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className="row align-items-center ">
                                                                    <div className="col-1 p-0">
                                                                        <i className="fa-solid fa-check text-blue s-18"></i>

                                                                    </div>

                                                                    <div className="col-auto text-dark fw-600">
                                                                        <p className='mb-0'>
                                                                            Agent limit 100
                                                                        </p>
                                                                    </div>

                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className="row align-items-center ">
                                                                    <div className="col-1 p-0 ">
                                                                        <i className="fa-solid fa-xmark text-danger s-18"></i>

                                                                    </div>

                                                                    <div className="col-auto text-muted fw-600">
                                                                        <p className='mb-0'>
                                                                            Support Service
                                                                        </p>
                                                                    </div>

                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className="row align-items-center ">
                                                                    <div className="col-1 p-0">
                                                                        <i className="fa-solid fa-xmark text-danger s-18"></i>

                                                                    </div>

                                                                    <div className="col-auto text-muted fw-600">
                                                                        <p className='mb-0'>
                                                                            Maintenance
                                                                        </p>
                                                                    </div>

                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className="row align-items-center ">
                                                                    <div className="col-1 p-0">
                                                                        <i className="fa-solid fa-xmark text-danger s-18"></i>

                                                                    </div>

                                                                    <div className="col-auto text-muted fw-600">
                                                                        <p className='mb-0'>
                                                                            Reports & Billing
                                                                        </p>
                                                                    </div>

                                                                </div>
                                                            </li>


                                                        </ul>
                                                    </div>

                                                    <div className="flip-card-back p-5 h-100">
                                                        <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reiciendis nobis
                                                            nisi perferendis nihil fugiat maiores temporibus laudantium magni earum ipsum.
                                                            Cum quae neque natus amet ullam aliquid ratione, possimus fuga.</p>

                                                    </div>
                                                </div>
                                            </div>



                                        </div>


                                        <div className="card-footer border-0 bg-white p-3 text-center ">
                                            <Link to="/sign-up" className='btn-subscribe rounded px-5 '>
                                                Subscribe
                                            </Link>
                                        </div>


                                    </div>
                                </div>

                                <div className="col-md-4 mb-3" data-aos="fade-up" data-aos-duration="1500">

                                    <div className="card rounded-5 p-3 overflow-hidden border-0 shadow">
                                        <div className="card-header  bg-white">
                                            <h5 className='fw-600 text-dark '>BASIC</h5>
                                            <h1 className='fw-700 text-blue '>
                                                $89.00 <span className='text-muted s-12'>/Year</span>
                                            </h1>
                                        </div>

                                        <div className="card-body border-0 p-0 h-100 border-0">
                                            <div className="flip-card border-0">

                                                <div className="flip-card-inner">

                                                    <div className="flip-card-front h-100">

                                                        <ul className=''>
                                                            <li>
                                                                <div className="row align-items-center ">
                                                                    <div className="col-1 p-0">
                                                                        <i className="fa-solid fa-check text-blue s-18"></i>

                                                                    </div>

                                                                    <div className="col-auto text-dark fw-600">
                                                                        Easy Customizable
                                                                    </div>

                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className="row align-items-center ">
                                                                    <div className="col-1 p-0">
                                                                        <i className="fa-solid fa-check text-blue s-18"></i>
                                                                    </div>

                                                                    <div className="col-auto text-dark fw-600">
                                                                        <p className='mb-0'>
                                                                            User license limit 1000
                                                                        </p>
                                                                    </div>

                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className="row align-items-center ">
                                                                    <div className="col-1 p-0">
                                                                        <i className="fa-solid fa-check text-blue s-18"></i>

                                                                    </div>

                                                                    <div className="col-auto text-dark fw-600">
                                                                        <p className='mb-0'>
                                                                            Agent limit 100
                                                                        </p>
                                                                    </div>

                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className="row align-items-center ">
                                                                    <div className="col-1 p-0 ">
                                                                        <i className="fa-solid fa-check text-blue s-18"></i>


                                                                    </div>

                                                                    <div className="col-auto text-dark fw-600">
                                                                        <p className='mb-0'>
                                                                            Support Service
                                                                        </p>
                                                                    </div>

                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className="row align-items-center ">
                                                                    <div className="col-1 p-0">
                                                                        <i className="fa-solid fa-check text-blue s-18"></i>


                                                                    </div>

                                                                    <div className="col-auto text-dark fw-600">
                                                                        <p className='mb-0'>
                                                                            Maintenance
                                                                        </p>
                                                                    </div>

                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className="row align-items-center ">
                                                                    <div className="col-1 p-0">
                                                                        <i className="fa-solid fa-xmark text-danger s-18"></i>

                                                                    </div>

                                                                    <div className="col-auto text-muted fw-600">
                                                                        <p className='mb-0'>
                                                                            Reports & Billing
                                                                        </p>
                                                                    </div>

                                                                </div>
                                                            </li>


                                                        </ul>
                                                    </div>

                                                    <div className="flip-card-back p-5 h-100">
                                                        <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reiciendis nobis
                                                            nisi perferendis nihil fugiat maiores temporibus laudantium magni earum ipsum.
                                                            Cum quae neque natus amet ullam aliquid ratione, possimus fuga.</p>

                                                    </div>
                                                </div>
                                            </div>



                                        </div>


                                        <div className="card-footer border-0 bg-white p-3 text-center ">
                                            <Link to="/sign-up" className='btn-subscribe rounded px-5 '>
                                                Subscribe
                                            </Link>
                                        </div>

                                        <div className="price_sale"><span className="popular">popular</span></div>


                                    </div>
                                </div>

                                <div className="col-md-4 mb-3" data-aos="fade-up" data-aos-duration="2000">

                                    <div className="card p-3 h-100 rounded-5 overflow-hidden border-0 shadow">
                                        <div className="card-header  bg-white">
                                            <h5 className='fw-600 text-dark '>PRO</h5>
                                            <h1 className='fw-700 text-blue '>
                                                $99.99 <span className='text-muted s-12'>/Year</span>
                                            </h1>
                                        </div>

                                        <div className="card-body p-0 h-100 border-0">
                                            <div className="flip-card border-0">

                                                <div className="flip-card-inner">

                                                    <div className="flip-card-front h-100">

                                                        <ul className=''>
                                                            <li>
                                                                <div className="row align-items-center ">
                                                                    <div className="col-1 p-0">
                                                                        <i className="fa-solid fa-check text-blue s-18"></i>

                                                                    </div>

                                                                    <div className="col-auto text-dark fw-600">
                                                                        Easy Customizable
                                                                    </div>

                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className="row align-items-center ">
                                                                    <div className="col-1 p-0">
                                                                        <i className="fa-solid fa-check text-blue s-18"></i>
                                                                    </div>

                                                                    <div className="col-auto text-dark fw-600">
                                                                        <p className='mb-0'>
                                                                            User license limit 1000
                                                                        </p>
                                                                    </div>

                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className="row align-items-center ">
                                                                    <div className="col-1 p-0">
                                                                        <i className="fa-solid fa-check text-blue s-18"></i>

                                                                    </div>

                                                                    <div className="col-auto text-dark fw-600">
                                                                        <p className='mb-0'>
                                                                            Agent limit 100
                                                                        </p>
                                                                    </div>

                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className="row align-items-center ">
                                                                    <div className="col-1 p-0 ">
                                                                        <i className="fa-solid fa-check text-blue s-18"></i>


                                                                    </div>

                                                                    <div className="col-auto text-dark fw-600">
                                                                        <p className='mb-0'>
                                                                            Support Service
                                                                        </p>
                                                                    </div>

                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className="row align-items-center ">
                                                                    <div className="col-1 p-0">
                                                                        <i className="fa-solid fa-check text-blue s-18"></i>


                                                                    </div>

                                                                    <div className="col-auto text-dark fw-600">
                                                                        <p className='mb-0'>
                                                                            Maintenance
                                                                        </p>
                                                                    </div>

                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className="row align-items-center ">
                                                                    <div className="col-1 p-0">
                                                                        <i className="fa-solid fa-xmark text-danger s-18"></i>

                                                                    </div>

                                                                    <div className="col-auto text-muted fw-600">
                                                                        <p className='mb-0'>
                                                                            Reports & Billing
                                                                        </p>
                                                                    </div>

                                                                </div>
                                                            </li>


                                                        </ul>
                                                    </div>

                                                    <div className="flip-card-back p-5 h-100">
                                                        <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reiciendis nobis
                                                            nisi perferendis nihil fugiat maiores temporibus laudantium magni earum ipsum.
                                                            Cum quae neque natus amet ullam aliquid ratione, possimus fuga.</p>

                                                    </div>
                                                </div>
                                            </div>



                                        </div>


                                        <div className="card-footer border-0 bg-white p-3 text-center ">
                                            <Link to="/sign-up" className='btn-subscribe rounded px-5 '>
                                                Subscribe
                                            </Link>
                                        </div>

                                    </div>
                                </div>

                            </>

                        )
                    }*/}


                </div>
            </div >

        </>
    )
}

export default Pricing