import React from 'react'
import popular from "../../Assets/Images/popular.webp";
import { useSelector } from 'react-redux';

const PowerfulData = () => {

    const { data } = useSelector((state => state.whyUs))

    return (
        <>
            <div className="container">
                <div className="row align-items-center py-5">
                    <div className="col-md-6 p-5">
                        <h2>
                            {/* Built on a powerful data & intelligence engine */}
                            {data?.built_engine?.heading}
                        </h2>

                        <p className='mt-5  s-20'>
                            {/* SeekOut's AI-powered data and intelligence engine brings together
                            data from external sources and your internal HR systems to surface insights in seconds. */}

                            {data?.built_engine?.description}
                        </p>
                    </div>

                    <div className="col-md-6 p-5">
                        <img src={data?.built_engine?.image} width="100%" alt="" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default PowerfulData