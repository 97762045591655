import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { baseURL, eventPageView } from '../../services/api'

const initialState = {
    data: {},
    loading: false,
    // homeData: [],
    // banner: {},
    // decode: {},
    // decodeCard: [],
    // feature: {},
    // featureCards: [],
    // feedback: [],
    // manageWork: {},
    // newsletter: {}
}

export const getEvents = createAsyncThunk(
    "event/getEvents",
    async () => {
        const res = await fetch(`${baseURL}${eventPageView}`, {
            // mode: 'no-cors',
            method: 'GET',
            headers: new Headers({
                'Accept': 'application/json',
                // 'Content-Type': 'application/json'
            })
        }
        )
        const resJson = await res.json()
        return resJson
    }
)

export const nextPage = createAsyncThunk(
    "event/nextPage",
    async (url) => {
        const res = await fetch(`${baseURL}${url}`, {
            // mode: 'no-cors',
            method: 'GET',
            headers: new Headers({
                'Accept': 'application/json',
                // 'Content-Type': 'application/json'
            })
        }
        )
        const resJson = await res.json()
        return resJson
    }
)

export const eventsSlice = createSlice({
    name: 'events',
    initialState,
    reducers: {},
    extraReducers: {
        [getEvents.pending]: (state) => {
            state.loading = true
        },
        [getEvents.fulfilled]: (state, { payload }) => {
            state.data = payload;
            state.loading = false

            // console.log("state.data ======  " , data) 
        },
        [getEvents.rejected]: (state) => {
            state.loading = false
            state.data = []
        },

        [nextPage.pending]: (state) => {
            state.loading = true
        },
        [nextPage.fulfilled]: (state, { payload }) => {
            state.data = payload;
            state.loading = false

            // console.log("state.data ======  " , data) 
        },
        [nextPage.rejected]: (state) => {
            state.loading = false
            state.data = []
        },
    }
})

export const eventsReducer = eventsSlice.reducer


