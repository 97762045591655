import React, { useEffect, useState } from 'react'
import Dcode from '../Components/Home/Dcode'
import HomeTopBanner from '../Components/Home/HomeTopBanner'
import ManageWork from '../Components/Home/ManageWork'
import Newsletter from '../Components/Home/Newsletter'
import Pricing from '../Components/Home/Pricing'
import SendMsg from '../Components/Home/SendMsg'
import Testimonials from '../Components/Home/Testimonials'
import Whyus from '../Components/Home/Whyus'
import ScrollToTop from '../Components/ScrollToTop'
import { webHeaderFooterView, webHomePageView } from '../services/api'
import useGet from '../hooks/useGet'
import Loader from '../Components/Loader/Loader'
import { useDispatch, useSelector } from 'react-redux'
import { getHome } from '../Redux/Slices/homeSlice'

const Home = () => {

    const dispatch = useDispatch();

    const { data, loading } = useSelector((state => state.home))



    useEffect(() => {
        dispatch(getHome())
    }, [])


    // const [data, loading, error] = useGet(`${webHomePageView}`)

    const [data2, loading2, error2] = useGet(`${webHeaderFooterView}`)

    console.log("data2 ==========", data2)
    const [banner, setBanner] = useState(null)
    const [manageWork, setManageWork] = useState(null)
    const [feature, setFeature] = useState({})
    const [featureCards, setFeatureCards] = useState([])
    const [decode, setDecode] = useState({})
    const [decodeCard, setDecodeCard] = useState({})
    const [newsletter, setNewsletter] = useState({})
    const [feedback, setFeedback] = useState([])
    const [footerContent, setFooterContent] = useState({})
    const [socialIcons, setSocialIcons] = useState([])
    const [time, setTime] = useState(false)

    useEffect(() => {
        if (data2) {
            // setBanner(data?.banner)
            // setManageWork(data?.manage_work)
            // setFeature(data?.feature)
            // setFeatureCards(data?.feature_cards)
            // setDecode(data?.decode)
            // setDecodeCard(data?.decode_cards)
            // setNewsletter(data?.newsletter)
            // setFeedback(data?.feedback)

            setFooterContent(data2?.footer_content)
            setSocialIcons(data2?.social_icons)
        }
    }, [data2])

    // useEffect(() => {
    //     setTimeout(() => {
    //         setTime(true)
    //     }, 0);
    // }, [])


    if (loading) return (<Loader />)


    // console.log("banner ========== ", banner)
    // console.log("managework ========== ", manageWork)
    // console.log("feature ========== ", feature)


    return (
        <>
            <ScrollToTop />

            {
                data?.banner ? (
                    <>
                        <HomeTopBanner banner={data?.banner} />
                        <ManageWork manageWork={data?.manage_work} />



                        <SendMsg footerContent={footerContent} socialIcons={socialIcons} />
                        <Pricing />


                        <Whyus feature={data?.feature} featureCards={data?.feature_cards} />
                        <Dcode decode={data?.decode} decodeCard={data?.decode_cards} />
                        <Testimonials feedback={data?.feedback} />
                        <Newsletter newsletter={data?.newsletter} />
                    </>

                ) :
                    (
                        // "loading"
                        <Loader />
                    )
            }

        </>
    )
}

export default Home