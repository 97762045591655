import React, { useEffect } from 'react'
import ScrollToTop from '../Components/ScrollToTop'
import GrowKnowledge from '../Components/WhyUs/GrowKnowledge'
import Oneplatform from '../Components/WhyUs/Oneplatform'
import PowerfulData from '../Components/WhyUs/PowerfulData'
import ProvenPartners from '../Components/WhyUs/ProvenPartners'
import RequestDemo from '../Components/WhyUs/RequestDemo'
import Success from '../Components/WhyUs/Success'
import TalentMarket from '../Components/WhyUs/TalentMarket'
import WhyUsScreen from '../Components/WhyUs/WhyUsScreen'
import { getWhyUs } from '../Redux/Slices/whyUsSlice'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../Components/Loader/Loader'
import HomeBlog from '../Components/WhyUs/HomeBlog'

const WhyUs = () => {

    const dispatch = useDispatch();
    const { data, loading } = useSelector((state => state.whyUs))



    useEffect(() => {
        dispatch(getWhyUs())
    }, [])

    if (loading) return <Loader />

    return (
        <>
            <ScrollToTop />
            <WhyUsScreen />
            <Oneplatform />
            <TalentMarket />
            <PowerfulData />
            <ProvenPartners />
            <Success />
            {/* <GrowKnowledge /> */}
            <HomeBlog />
            <RequestDemo />
        </>
    )
}

export default WhyUs