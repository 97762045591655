import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LayoutScreen from "../Components/Layout/LayoutScreen";
import Blog from '../Pages/Blog';
import ContactUs from '../Pages/ContactUs';
import Events from '../Pages/Events';
import Home from '../Pages/Home';
import PricingPlane from '../Pages/PricingPlane';
import PrivacyPolicy from '../Pages/PrivacyPolicy';
import SignIn from '../Pages/SignIn';
import SignUp from '../Pages/SignUp';
import TermsAndCondition from '../Pages/TermsAndCondition';
import WhyUs from '../Pages/WhyUs';
import { webHeaderFooterView } from '../services/api';
import useGet from '../hooks/useGet';


const AppRouting = () => {

    const [data, loading, error] = useGet(`${webHeaderFooterView}`)

    console.log("data ============= ", data)
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<LayoutScreen />}>
                    {data?.header?.map((item, index) => {
                        return (
                            <Route
                                index={item?.slug === ''}
                                path={item?.slug}
                                element={item.id == '1' && <Home /> || item.id == '2' && <WhyUs /> || item.id == '3' && <PricingPlane /> || item.id == '4' && <Blog /> || item.id == '5' && <Events /> || item.id == '6' && <ContactUs />}
                            />
                        )
                    })}
                    <Route path='sign-in' element={<SignIn />} />
                    <Route path='sign-up' element={<SignUp />} />
                    <Route path='sign-up/:id' element={<SignUp />} />
                    {/* <Route index element={<Home />} />
                    <Route path='privacy-policy' element={<PrivacyPolicy />} />
                    <Route path='terms-and-conditions' element={<TermsAndCondition />} />
                    <Route path='contact-us' element={<ContactUs />} />
                    <Route path='pricing' element={<PricingPlane />} />
                    <Route path='blogs' element={<Blog />} />
                    <Route path='why-us' element={<WhyUs />} />
                    <Route path='sign-in' element={<SignIn />} />
                    <Route path='events' element={<Events />} /> */}
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export default AppRouting