import React, { useEffect, useState } from 'react'
import logo from "../../Assets/Images/logo.png";
import './Footer.css'
import { Link } from 'react-router-dom';

const FooterScreen = ({ footerContent, socialIcons }) => {

    // const [socialLinks, setSocialLinks] = useState([])


    // useEffect(() => {
    //     setSocialLinks(footerContent?.socialIcons)
    // }, [])
    return (
        <>
            {
                footerContent ? (
                    <>
                        <div className="footer py-5">
                            <div className="container">
                                <div className="row footer-color">
                                    <div className="col-md-6 col-lg-4 mb-3 col-sm-6">
                                        <img
                                            src={footerContent?.logo}
                                            alt="" width="200px" />

                                        <p className='mt-3'>

                                            {footerContent?.description}
                                            {/* Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure fuga animi eveniet, quaerat voluptatum. */}
                                        </p>

                                        <h5 className='fw-600 mb-3'>Download the App</h5>

                                        <button className='btn-footer from-top  px-3 py-2 '>
                                            <div className="row">
                                                <div className="col-auto">
                                                    <i className="fa-brands fa-google-play s-18" />

                                                </div>

                                                <div className="col ps-0 fw-600">
                                                    Google Play
                                                </div>
                                            </div>
                                        </button>

                                        &nbsp;&nbsp;

                                        <button className='btn-footer px-3 py-2'>
                                            <div className="row">
                                                <div className="col-auto">
                                                    <i className="fa-brands fa-apple s-18" />

                                                </div>

                                                <div className="col ps-0 fw-600">
                                                    App Store
                                                </div>
                                            </div>
                                        </button>



                                    </div>

                                    <div className="col-md-6 col-lg-2 col-sm-6">
                                        <h5 className='fw-700 mb-4'>
                                            {footerContent?.userfull_title}

                                            {/* Usefull Links */}
                                        </h5>
                                        <ul className='ps-0'>
                                            <Link to="">
                                                <li>
                                                    <div className="row">
                                                        <div className="col-auto pe-0">

                                                            <i className="fa-regular fa-circle-dot me-2"></i>
                                                        </div>

                                                        <div className="col p-0">
                                                            Trust & Safety
                                                        </div>
                                                    </div>
                                                </li>
                                            </Link>

                                            <Link to="">
                                                <li>
                                                    <div className="row">
                                                        <div className="col-auto pe-0">

                                                            <i className="fa-regular fa-circle-dot me-2"></i>
                                                        </div>

                                                        <div className="col p-0">
                                                            Cookie Policy
                                                        </div>
                                                    </div>
                                                </li>
                                            </Link>

                                            <Link to="/contact-us">
                                                <li>
                                                    <div className="row">
                                                        <div className="col-auto pe-0">

                                                            <i className="fa-regular fa-circle-dot me-2"></i>
                                                        </div>

                                                        <div className="col p-0">
                                                            Contact Us
                                                        </div>
                                                    </div>
                                                </li>
                                            </Link>

                                            <Link to="/privacy-policy">
                                                <li>
                                                    <div className="row">
                                                        <div className="col-auto pe-0">

                                                            <i className="fa-regular fa-circle-dot me-2"></i>
                                                        </div>

                                                        <div className="col p-0">
                                                            Privacy Policy
                                                        </div>
                                                    </div>
                                                </li>
                                            </Link>

                                            <Link to="/terms-and-conditions">
                                                <li>
                                                    <div className="row">
                                                        <div className="col-auto pe-0">

                                                            <i className="fa-regular fa-circle-dot me-2"></i>
                                                        </div>

                                                        <div className="col p-0">
                                                            Terms of Service
                                                        </div>
                                                    </div>
                                                </li>
                                            </Link>

                                            <Link to="/pricing-&-planes">
                                                <li>
                                                    <div className="row">
                                                        <div className="col-auto pe-0">

                                                            <i className="fa-regular fa-circle-dot me-2"></i>
                                                        </div>

                                                        <div className="col p-0">
                                                            Pricing & Plans
                                                        </div>
                                                    </div>
                                                </li>
                                            </Link>



                                        </ul>
                                    </div>

                                    <div className="col-md-6 col-lg-2 col-sm-6">
                                        <h5 className='fw-700 mb-4'>
                                            {footerContent?.support_title}

                                            Support
                                        </h5>
                                        <ul className='ps-0'>
                                            <Link to="">
                                                <li>
                                                    <div className="row">
                                                        <div className="col-auto pe-0">

                                                            <i className="fa-regular fa-circle-dot me-2"></i>
                                                        </div>

                                                        <div className="col p-0">
                                                            Documentation
                                                        </div>
                                                    </div>
                                                </li>
                                            </Link>

                                            <Link to="">
                                                <li>
                                                    <div className="row">
                                                        <div className="col-auto pe-0">

                                                            <i className="fa-regular fa-circle-dot me-2"></i>
                                                        </div>

                                                        <div className="col p-0">
                                                            FAQ
                                                        </div>
                                                    </div>
                                                </li>
                                            </Link>

                                            <Link to="">
                                                <li>
                                                    <div className="row">
                                                        <div className="col-auto pe-0">

                                                            <i className="fa-regular fa-circle-dot me-2"></i>
                                                        </div>

                                                        <div className="col p-0">
                                                            Community
                                                        </div>
                                                    </div>
                                                </li>
                                            </Link>

                                            <Link to="/events">
                                                <li>
                                                    <div className="row">
                                                        <div className="col-auto pe-0">

                                                            <i className="fa-regular fa-circle-dot me-2"></i>
                                                        </div>

                                                        <div className="col p-0">
                                                            Events
                                                        </div>
                                                    </div>
                                                </li>
                                            </Link>

                                            <Link to="/blogs">
                                                <li>
                                                    <div className="row">
                                                        <div className="col-auto pe-0">

                                                            <i className="fa-regular fa-circle-dot me-2"></i>
                                                        </div>

                                                        <div className="col p-0">
                                                            Blogs
                                                        </div>
                                                    </div>
                                                </li>
                                            </Link>




                                        </ul>
                                    </div>

                                    <div className="col-md-6 col-lg-4 col-sm-4">
                                        <h5 className='fw-700 mb-4'>
                                            {footerContent?.update_title}
                                            {/* Stay Updated */}
                                        </h5>

                                        <ul className='ps-0'>
                                            <li>
                                                <div className="row">
                                                    <div className="col-auto pe-0">

                                                        <i className="fa-solid fa-envelope-open-text me-2 s-22" />
                                                    </div>

                                                    <div className="col p-0">
                                                        {footerContent?.email}

                                                        {/* support@insurance.net */}
                                                    </div>
                                                </div>
                                            </li>

                                            <li>
                                                <div className="row">
                                                    <div className="col-auto pe-0">

                                                        <i className="fa-sharp fa-solid fa-phone me-2 s-22" />
                                                    </div>

                                                    <div className="col p-0">
                                                        <a
                                                            href={`tel: +1 (234) 567-890`}
                                                            className=" text-white"
                                                        >
                                                            {footerContent?.contact}

                                                        </a>
                                                    </div>
                                                    {/* <div className="col p-0">

                                                        {footerContent?.contact}

                                                        +1 (234) 567-890
                                                    </div> */}
                                                </div>
                                            </li>

                                            <li>
                                                <div className="row">
                                                    <div className="col-auto pe-0">

                                                        <i className="fa-sharp fa-solid fa-location-dot me-2 s-22"></i>
                                                    </div>

                                                    <div className="col p-0">
                                                        {footerContent?.address}

                                                        {/* 49 Uniqe Square D, New York, NY 10003, USA */}
                                                    </div>
                                                </div>
                                            </li>






                                        </ul>

                                        <div className="row">

                                            {Array.isArray(socialIcons) && (
                                                socialIcons.map((item, index) => {

                                                    return (
                                                        <>
                                                            <div key={index} className="col-auto">

                                                                <a href={item?.link} target="_blank" rel="noopener noreferrer">

                                                                    <div className='link-btn'>

                                                                        <img src={item?.icon} width="22px" alt="" />
                                                                        {/* <i className="fa-brands fa-facebook-f s-22"></i> */}
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </>
                                                    )

                                                })
                                            )}
                                            {/* <div className="col-auto">

                                    <a href="https://www.facebook.com/sacredthemes/" target="_blank" rel="noopener noreferrer">

                                        <div className='link-btn'>
                                            <i className="fa-brands fa-facebook-f s-22"></i>
                                        </div>
                                    </a>
                                </div>

                                <div className="col-auto">

                                    <a href="https://www.linkedin.com/company/sacredthemes/" target="_blank" rel="noopener noreferrer">

                                        <div className='link-btn '>
                                            <i className="fa-brands fa-linkedin-in s-22"></i>
                                        </div>
                                    </a>

                                </div>
                                <div className="col-auto">

                                    <a href="https://twitter.com/SacredThemes" target="_blank" rel="noopener noreferrer">

                                        <div className='link-btn '>
                                            <i className="fa-brands fa-twitter s-22"></i>
                                        </div>
                                    </a>

                                </div> */}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : ""
            }

        </>
    )
}

export default FooterScreen