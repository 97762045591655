import React ,{useState} from 'react'
import Pricing from '../Home/Pricing'

const PricingPlaneScreen = () => {

    return (
        <>
            <div className="container" >
                <div className="row">
                    <Pricing />
                </div>
            </div>
        </>
    )
}

export default PricingPlaneScreen