import React from 'react'
import { Link } from 'react-router-dom'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

const SignInScreen = () => {
    return (
        <>
            <section className='top-banner' style={{marginTop:"5rem"}}>
                <div className="container">
                    <div className="row py-5 mb-5 justify-content-center">
                        <div className="col-md-7">
                            <div className="card p-md-5 p-3 rounded-5 border-0">
                                <h3 className='text-center text-blue fw-700'>Login </h3>
                                <h6 className='text-center fw-600 '>Don't have an account yet?
                                    <Link> Sign Up </Link></h6>


                                <Form className='pt-4 row'>
                                    <Form.Group className="mb-3 col-12" controlId="formBasicEmail">
                                        <Form.Label className='fw-600 s-14'>Email </Form.Label>
                                        <Form.Control type="email" className='py-3' placeholder="Enter Your Email" />

                                    </Form.Group>

                                    <Form.Group className="mb-3 col-12" controlId="formBasicPassword">
                                        <Form.Label className='fw-600 s-14'>Password</Form.Label>
                                        <Form.Control type="password" className='py-3' placeholder="Enter Your Password" />
                                    </Form.Group>

                                    <Form.Group className="mb-3 col-6" controlId="formBasicCheckbox">
                                        <Form.Check type="checkbox" className='fw-600 s-14' label="Check me out" />
                                    </Form.Group>

                                    <div className="mb-3 col-6 text-end fw-600 s-14"  >
                                        <Link>Forgot Password?</Link>
                                    </div>

                                    <div className="col-12">

                                        <Button className='w-100 btn-subscribe mb-3' type="submit">
                                            Login
                                        </Button>
                                        <hr />
                                        <div className='fw-700 s-20 text-blue text-center'>
                                            OR
                                        </div>
                                        <hr />
                                        <Button className='w-100 btn-subscribe mb-3' type="submit">
                                            Continue With google
                                        </Button>

                                        <Button className='w-100 btn-subscribe mb-3' type="submit">
                                            continue with facebook
                                        </Button>
                                    </div>


                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SignInScreen