import React from 'react'
import AdobeStock from "../../Assets/Images/AdobeStock.webp";
import { useSelector } from 'react-redux';

const Success = () => {

    const { data } = useSelector((state => state.whyUs))

    return (
        <>
            <section className='light-gradient'>

                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-md-6 ">
                            <div className="row ps-5 align-items-center">
                                <div className="col-md-12">


                                    <h3 className='ps-5'>
                                        {/* Success starts with people */}
                                        {data?.success_people?.heading}
                                    </h3>

                                    <p className='ps-5'>
                                        {/* Your people define your culture and help you achieve your goals. That’s why SeekOut takes
                                        a people-first approach, powering both company and career growth. */}
                                        {data?.success_people?.description}



                                    </p>

                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 pe-0">
                            <img src={data?.success_people?.image} width="100%" alt="" />

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Success