import React, { useState } from 'react'
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from "../../Assets/Images/logo.png";
import { Link, useLocation } from 'react-router-dom';
import './Navbar.css'


const NavbarScreen = ({ header, footerContent }) => {

    // console.log("header props ====== ", header?.)

    const location = useLocation()
    console.log("location ", location)

    const [navColor, setNavColor] = useState(false)
    const [nav, setNav] = ("home")

    const changeNavBg = () => {
        if (window.scrollY >= 100) {
            setNavColor(true)
        }
        else {
            setNavColor(false)
        }
    }

    window.addEventListener('scroll', changeNavBg)

    return (
        <>
            <div className='position-relative '  >

                <Navbar className='active ' fixed="top" expand="lg">

                    <Container fluid>
                        <Link to='/'>
                            {footerContent ? (
                                <div className='navbar-brand'>
                                    <img src={footerContent?.logo} alt="" width="200px"
                                        height="" />
                                </div>
                            ) : ""}

                        </Link>
                        <Navbar.Toggle aria-controls="navbarScroll" />
                        <Navbar.Collapse id="navbarScroll" >



                            <Nav
                                className="m-md-auto my-2 my-lg-0"
                                style={{ maxHeight: '100px' }}
                                navbarScroll
                            >

                                {
                                    header?.map((items, i) => {
                                        return (
                                            <>
                                                <Link
                                                    className={` px-3 ${location?.pathname == '/' + items?.slug ? 'text-blue fw-600 ' : 'nav-text '}`}
                                                    to={items?.slug}>
                                                    {items?.name}
                                                </Link >
                                            </>
                                        )
                                    })
                                }
                                {/* <Link
                                    className={` px-3 ${location?.pathname == '/' ? 'text-blue fw-600 ' : 'nav-text '}`}
                                    to={"/"}>
                                    Home
                                </Link>

                                <Link
                                    to={"/why-us"}
                                    className={`px-3 ${location?.pathname == '/why-us' ? 'text-blue fw-600' : 'nav-text '}`}
                                >
                                    Why Us
                                </Link>

                                <Link
                                    to={"/pricing-&-plans"}
                                    className={`px-3 ${location?.pathname == '/pricing-&-plans' ? 'text-blue fw-600' : 'nav-text '}`}>
                                    Pricing
                                </Link>

                                <Link
                                    to={"/blogs"}
                                    className={`px-3 ${location?.pathname == '/blogs' ? 'text-blue fw-600' : 'nav-text'}`}
                                >
                                    Blogs
                                </Link>

                                <Link
                                    to={"/events"}
                                    className={`px-3  ${location?.pathname == '/events' ? 'text-blue fw-600' : 'nav-text '}`}
                                >
                                    Events
                                </Link>

                                <Link
                                    //  className='nav-link' to={"/contact-us"}
                                    to={"/contact-us"}
                                    className={`px-3 ${location?.pathname == '/contact-us' ? 'text-blue fw-600' : 'nav-text'}`}
                                >
                                    Contact Us
                                </Link> */}

                                {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                                    <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                                    <NavDropdown.Item href="#action/3.2">
                                        Another action
                                    </NavDropdown.Item>
                                    <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item href="#action/3.4">
                                        Separated link
                                    </NavDropdown.Item>
                                </NavDropdown> */}
                            </Nav>

                            <Form className="d-flex align-items-center">

                                <Link to="/sign-in"
                                    className={`px-3 ${location?.pathname == '/sign-in' ? 'text-blue fw-600' : 'text-blue nav-text'}`}
                                >
                                    Sign In
                                </Link>


                                <Link to="/sign-up" className='join-btn'>LET'S JOIN</Link>

                            </Form>

                        </Navbar.Collapse>
                    </Container>

                </Navbar>
            </div >


        </>
    )
}

export default NavbarScreen