// export const baseURL = 'https://ibroker.technottix.com/api/';

// new Base URL
export const baseURL = 'https://ibroker-be.hiteclogs.com/api/';


// constant variables
export const paymentCharges = `payment-web`;
export const packages = `packages`;
export const saveLead = `save-lead`;
export const webHeaderFooterView = `web-header-footer-view`;
export const webContactPageView = `web-contact-page-view`;
export const webHomePageView = `web-home-page-view`;
export const webWhyUsPageView = `web-why-us-page-view`;
export const webBlogPageView = `web-blog-page-view`;
export const eventPageView = `web-event-page-view`;
export const createUser = `chat-user-register`;
export const visitorInsert = `visitor-insert`;
export const sendMessage = `chat-message-send`;
export const insertNewletter = `insert-newletter`;


// fetch methods
export const post = async (url, data) => {
    try {
        const res = await fetch(baseURL + url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({
                accept: 'application/json',
                'Content-Type': 'application/json'
            })
        });
        return await res.json();
    } catch (e) {
        console.log('e', e.message);
    }
}

export const get = async (url) => {
    try {
        const res = await fetch(baseURL + url, {
            method: 'GET',
            headers: new Headers({
                accept: 'application/json'
            })
        });
        return await res.json();
    } catch (e) {
        console.log('e', e.message);
    }
}