import React, { useState } from 'react'
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import { saveLead } from '../../services/api';
import usePost from '../../hooks/usePost';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2'


const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 1500,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})


const SendMsg = ({ footerContent, socialIcons }) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setError,
        setValue,
    } = useForm()


    const [validated, setValidated] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [subject, setSubject] = useState("");
    const [requirement, setRequirement] = useState("");


    const [nameError, setNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [subjectError, setSubjectError] = useState(false);
    const [requirementError, setRequirementError] = useState(false);

    const [signUpRequest, loading, error] = usePost()



    const onSubmit = async (data) => {

        if (name.trim() === "") {
            setNameError(true)
            return
        }
        if (phone.trim() === "") {
            setPhoneError(true)
            return
        }
        if (email.trim() === "") {
            setEmailError(true)
            return
        }

        if (subject.trim() === "") {
            setSubjectError(true)
            return
        }

        if (requirement.trim() === "") {
            setRequirementError(true)
            return
        }

        data.name = name
        data.email = email
        data.phone = phone
        data.subject = subject
        data.requirement = requirement

        const res = await signUpRequest(saveLead, data)
        if (res?.status === 'success') {
            // window.open(`${res?.msg}`, '_blank');
            // alert(res?.msg)
            Toast.fire({
                icon: 'success',
                title: res?.msg
            })
            reset()

            setName("")
            setPhone("")
            setEmail("")
            setRequirement("")
            setSubject("")
        }
        res?.errors && Object.keys(res?.errors).map((key) => {
            res?.errors[key].map((value) => {
                // setError(`${key}`, { type: "manual", message: `${value}` })
            })
        })
    }


    return (
        <>
            <div className="container">
                <div className="row my-5">
                    {footerContent ? (
                        <>
                            <div className="col-md-12" data-aos="fade-down"
                                data-aos-easing="linear"
                                data-aos-duration="1000">
                                <div className="card overflow-hidden card-shadow rounded-5 border-0">
                                    <div className="row">
                                        <div className="col-lg-8 p-4 p-md-5">

                                            <h4 className='fw-700 mb-4'>Contact Us</h4>
                                            <Form noValidate validated={validated} onSubmit={handleSubmit(onSubmit)}>
                                                <Row className="mb-3">

                                                    <Form.Group as={Col} md="6" className='mb-3 ' controlId="validationCustom03">
                                                        {/* <Form.Label>City</Form.Label> */}
                                                        <Form.Control value={name} onChange={(e) => setName(e.target.value) + setNameError(false)} type="text" placeholder="Full Name" required className='py-3' />
                                                        {nameError && (
                                                            <small className='text-danger'>
                                                                Name field is Required.
                                                            </small>
                                                        )}

                                                    </Form.Group>

                                                    <Form.Group className='mb-3 ' as={Col} md="6" controlId="validationCustom03">
                                                        {/* <Form.Label>City</Form.Label> */}
                                                        <Form.Control value={phone} onChange={(e) => setPhone(e.target.value) + setPhoneError(false)} className='py-3' type="number" placeholder="Phone" required />
                                                        {phoneError && (
                                                            <small className='text-danger'>
                                                                Phone field is required
                                                            </small>
                                                        )}
                                                    </Form.Group>

                                                    <Form.Group className='mb-3' as={Col} md="12" controlId="validationCustom03">
                                                        {/* <Form.Label>City</Form.Label> */}
                                                        <Form.Control value={email} onChange={(e) => setEmail(e.target.value) + setEmailError(false)} className='py-3' type="email" placeholder="Email" required />


                                                        {emailError && (
                                                            <small className='text-danger'>
                                                                Email field is Required.
                                                            </small>
                                                        )}
                                                    </Form.Group>

                                                    <Form.Group className='mb-3' as={Col} md="12" controlId="validationCustom04">
                                                        {/* <Form.Label>State</Form.Label> */}
                                                        <Form.Control
                                                            value={subject} onChange={(e) => setSubject(e.target.value) + setSubjectError(false)} className='py-3' type="text" placeholder="How Can I Help You?" required />


                                                        {subjectError && (
                                                            <small className='text-danger'>
                                                                Subject field is Required.
                                                            </small>
                                                        )}
                                                    </Form.Group>

                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                        <Form.Control as="textarea" value={requirement} onChange={(e) => setRequirement(e.target.value) + setRequirementError(false)} rows={5} placeholder="Tell me more about the requirement" required />
                                                        {requirementError && (
                                                            <small className='text-danger'>
                                                                This field is Required.
                                                            </small>
                                                        )}
                                                    </Form.Group>

                                                </Row>

                                                <button className='btn-theme rounded'>Send message</button>
                                            </Form>
                                        </div>

                                        <div className="col-lg-4 top-theme p-5">
                                            <h4 className='fw-700 text-white'>Contact Information</h4>

                                            <ul className='ps-0'>
                                                <li>
                                                    <div className="row ">
                                                        <div className="col-auto pe-0">

                                                            <i className="fa-solid fa-envelope-open-text me-2 s-22" />
                                                        </div>

                                                        <div className="col p-0 fw-500">
                                                            {footerContent?.email}
                                                            {/* support@insurance.net */}
                                                        </div>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div className="row ">
                                                        <div className="col-auto pe-0">

                                                            <i className="fa-sharp fa-solid fa-phone me-2 s-22" />
                                                        </div>

                                                        <div className="col p-0 fw-500">
                                                            {footerContent?.contact}

                                                            {/* +1 (234) 567-890 */}
                                                        </div>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div className="row ">
                                                        <div className="col-auto pe-0">

                                                            <i className="fa-sharp fa-solid fa-location-dot me-2 s-22"></i>
                                                        </div>

                                                        <div className="col p-0 fw-500">
                                                            {footerContent?.address}

                                                            {/* 49 Uniqe Square D, New York, NY 10003, USA */}
                                                        </div>
                                                    </div>
                                                </li>

                                            </ul>

                                            <div className="row">

                                                {Array.isArray(socialIcons) && (
                                                    socialIcons.map((item, index) => {

                                                        return (
                                                            <>
                                                                <div key={index} className="col-auto">

                                                                    <a href={item?.link} target="_blank" rel="noopener noreferrer">

                                                                        <div className='link-btn'>

                                                                            <img src={item?.icon} width="22px" alt="" />
                                                                            {/* <i className="fa-brands fa-facebook-f s-22"></i> */}
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            </>
                                                        )

                                                    })
                                                )}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : ""}

                </div>
            </div>
        </>
    )
}

export default SendMsg