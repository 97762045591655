import React, {useState} from 'react';
import ChatBox from "./ChatBox";

const Chat = () => {
    const [chatBox, setChatBox] = useState(false);
    const [chatOptions, setChatOptions] = useState(false);

    return (
        <>
            <div
                className="position-fixed p-3 bottom-0 end-0"
                style={{ zIndex: "9999" }}
            >
                {chatBox ? (
                    <>
                        <ChatBox closeChat={() => setChatBox(false)}/>
                    </>
                ) : (
                    <></>
                )}

                {chatBox ? (
                    <></>
                ) : (
                    <>
                        {chatOptions === true && (
                            <>
                                <button
                                    onClick={() => setChatBox(true)}
                                    className="ms-auto mt-2 btn btn-light shadow justify-content-center align-items-center rounded-circle d-flex"
                                    style={{ height: "52px", width: "52px" }}
                                >
                                    <i className="fa-sharp fa-solid fa-comment-dots text-success s-24 d-block" />
                                </button>

                                <a
                                    href={`tel: +1 (234) 567-890`}
                                    className="ms-auto mt-2 btn btn-primary shadow text-light justify-content-center align-items-center rounded-circle d-flex p-2 "
                                    style={{ height: "48px", width: "48px" }}
                                >
                                    <i className="fa-solid fa-phone s-20 d-block" />
                                </a>

                                {/* <a
                                    href={''}
                                    className="ms-auto mt-2 btn text-light shadow justify-content-center align-items-center rounded-circle d-flex p-2"
                                    style={{
                                        height: "48px",
                                        width: "48px",
                                        backgroundColor: "#25D366",
                                    }}
                                    target="_blank"
                                >
                                    <i className="fa-sharp fa-solid fa-envelope d-block s-20" />
                                </a> */}
                            </>
                        )}

                        {!chatOptions && <div className="pulsating-circle"></div>}

                        <div className={`${!chatOptions ? "pulse" : " "} rounded-circle`}>
                            <button
                                onClick={() => setChatOptions(!chatOptions)}
                                className="btn-shake  ms-auto p-0 mt-2 btn btn-dark shadow justify-content-center align-items-center rounded-circle d-flex"
                                style={{ height: "52px", width: "52px" }}
                            >
                                {chatOptions ? (
                                    <>
                                        <i className="fa-solid fa-xmark d-block s-24" />
                                    </>
                                ) : (
                                    <>
                                        <i className="fa-sharp fa-solid fa-bars d-block s-24" />
                                    </>
                                )}
                            </button>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default Chat;