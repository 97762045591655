import React from 'react'

const BlogsBanner = ({ banner }) => {
    return (
        <>
            <div className="newsletter" style={{marginTop:"5rem"}}>
                <div className="container">
                    <div className="row py-5">
                        <div className="col-md-12">


                            <br />
                            <br />

                            <h1 className='text-white text-center'>
                                {/* Blogs & News */}
                                {banner?.heading}
                            </h1>
                            <p className='text-center text-white fw-600'>
                                {/* The latest news and updates from iBroker */}
                                {banner?.description}
                            </p>
                            <br />
                            <br />

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BlogsBanner