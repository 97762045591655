import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { baseURL, webHomePageView } from "../../services/api";

const initialState = {
  data: {},
  // homeData: [],
  // banner: {},
  // decode: {},
  // decodeCard: [],
  // feature: {},
  // featureCards: [],
  // feedback: [],
  // manageWork: {},
  // newsletter: {}
};

export const userSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    saveUser: (state, { payload }) => {
      state.data = payload;
    },
  },
});

export const { saveUser } = userSlice.actions;

export const userReducer = userSlice.reducer;
