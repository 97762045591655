import React, { useEffect, useState } from 'react';
import { baseURL } from "../services/api";

const useGet = (url, isLoading=true) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        // setLoading(true);
        const fetchData = async () => {
            setError(null);
            try {
                const res = await fetch(baseURL + url, {
                    method: 'GET',
                    headers: new Headers({
                        accept: 'application/json'
                    })
                });
                setLoading(false);
                setData(await res.json());
            } catch (e) {
                setLoading(false);
                setError(e);
                console.log('e', e.message);
            }
        }
        fetchData();
        // setTimeout(() => {
        //     setLoading(false);

        // }, 10000);
    }, [url])

    return [data, loading, error, setData];
};

export default useGet;