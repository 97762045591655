import React from 'react'
import "./Home.css"
import top from "../../Assets/Images/laptop-screen.png";
import { useSelector } from 'react-redux';
const HomeTopBanner = (banner) => {

    console.log(" ================  ", banner)

    const { data } = useSelector((state => state.home))

    console.log("data123 =====" , data?.banner)
    return (
        <>

            <div className="top-banner  top-0" style={{marginTop:"5rem"}}>
                <div className="container-fluid">

                    {banner?.banner ? (
                        <>
                            <div className="row py-5 align-items-center">
                                <div className="col-lg-6 ps-md-5 py-5" data-aos="fade-right" data-aos-duration="2000">
                                    <h1 className='fw-700 text-white'>
                                        {banner?.banner?.banner_title}
                                        {/* We Deliver the <span className='font-alegreya italic'>AGENT </span> you need. */}
                                    </h1>
                                    <p className='fw-500 text-white'>
                                        {banner?.banner?.banner_tag_line}

                                        {/* No.1 Agents Recuriting CRM in the Universe. */}
                                    </p>

                                    <button className='btn.glow text-uppercase bg-white py-2 px-4 border mb-2 mb--md-0  rounded s-16 text-muted fw-700'>
                                        {/* Try for free */}
                                        {banner?.banner?.try_for_free_button}

                                    </button>
                                    &nbsp;
                                    &nbsp;
                                    <button className='text-uppercase btn-play py-2 px-4 rounded '><i className="fa-solid fa-play me-2" />
                                        {/* watch trail */}
                                        {banner?.banner?.watch_trial_button}

                                    </button>
                                </div>

                                <div className="col-md-6 p-0 d-none d-lg-block position-relative" data-aos="fade-left" data-aos-duration="2000">
                                    <div className='shake-y'>
                                        <img
                                            src={banner?.banner?.banner_image}

                                            width="150%" alt="banner-img" />

                                    </div>

                                </div>
                            </div>
                        </>
                    ) : ""}

                </div>
            </div>
        </>
    )
}

export default HomeTopBanner