import React, { Fragment, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import Pusher from "pusher-js";
import useGet from "../../hooks/useGet";
import { baseURL } from "../../services/api";

const ChatList = ({ id }) => {
  const [data, setData] = useState([]);
  const [chatData, setChatData] = useState([{ id: 1 }, { id: 2 }]);

  useEffect(() => {
    // setLoading(true);
    const fetchData = async () => {
      // setError(null);
      try {
        let res = await fetch(baseURL + `get-user-messages/${id}`, {
          method: "GET",
          headers: new Headers({
            accept: "application/json",
          }),
        });
        res = await res.json();
        // setLoading(false);
        res?.status == "success" && setData(res?.data);
      } catch (e) {
        // setLoading(false);
        // setError(e);
        console.log("e", e.message);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const pusher = new Pusher("bf8e1084560682a9225c", {
      cluster: "ap1",
    });
    const channel1 = pusher.subscribe("ibroker-channel");

    channel1.bind("iborker-event", function (newData) {
      console.log("newdata-logout", newData, newData?.user_id == id);
      if (newData?.user_id == id) {
        setData((pre) => [...pre, newData]);
      }
    });

    return () => {
      pusher.unsubscribe("ibroker-channel");
    };
  }, []);

  return (
    <>
      <div
        id="scrollableDiv"
        style={{
          height: 300,
          overflow: "auto",
          display: "flex",
          flexDirection: "column-reverse",
        }}
      >
        <InfiniteScroll
          dataLength={chatData.length}
          // next={fetchMoreData}
          style={{
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
          }}
          className="px-2"
          inverse={true}
          hasMore={true}
          //   loader={<h6 className="text-center">Loading...</h6>}
          scrollableTarget="scrollableDiv"
        >
          {data?.length > 0 &&
            data?.map((item, index) => (
              <Fragment key={index}>
                {item?.sender_id == id ? (
                  <div className="col-12">
                    <div className="row align-items-end mt-2 m-0">
                      <div className="col pe-0">
                        <div className="fit-content  bg-light shadow-sm p-1 px-2 rounded-2 s-12 border ms-auto">
                          {item?.message}
                        </div>
                        <p className="s-10 mb-0 text-end mt-1">
                          {moment(item?.created_at).format("DD-MMM-YY h:mm A")}
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="col-12">
                    <div className="row align-items-start mt-2 m-0">
                      <div className="col p-0">
                        <div className="fit-content shadow-sm s-12 bg-chat text-light p-1 px-2 rounded-2 border">
                          {/* <EmojiConverter text={item?.message} /> */}
                          {item?.message}
                        </div>
                        <p className=" mb-0 mt-1 s-10">
                          {moment(item?.created_at).format("DD-MMM-YY h:mm A")}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </Fragment>
            ))}
        </InfiniteScroll>
      </div>
    </>
  );
};

export default ChatList;
