import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import contactus from "../../Assets/Images/contactus.png";
import { webContactPageView } from '../../services/api';
import useGet from '../../hooks/useGet';
import Loader from '../Loader/Loader';

const ContactUsScreen = () => {

    const [data, loading, error] = useGet(`${webContactPageView}`)

    if (loading) return (<Loader />)
    return (
        <>
            <div className="top-banner" style={{ marginTop: "5rem" }}>


                {data ? (
                    <div className="container">
                        <div className="row mb-5">
                            <div className="col-md-6 py-5">
                                <h1 className='fw-700 text-white'>
                                    {/* How can we help you? */}

                                    {data?.data?.title}
                                </h1>
                                <p className='text-white s-20'>
                                    {/* Have a question and want to learn more? Fill out
                                    this form and we'll get in touch with you shortly. */}

                                    {data?.data?.description}

                                </p>

                                <img src={data?.data?.image} width="100%" alt="" className='p-5' />
                            </div>

                            <div className="col-md-6 p-5">
                                <div className="card border-bottom border-5 border-primary rounded-5 p-4">
                                    <h4 className='fw-700 '>Contact Us</h4>

                                    <Form>
                                        <Form.Group className="mb-3" controlId="formFirstName">
                                            <Form.Label className="fw-600 s-14">First Name:</Form.Label>
                                            <Form.Control type="text" placeholder="First Name" />
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="formLastName">
                                            <Form.Label className="fw-600 s-14">Last Name:</Form.Label>
                                            <Form.Control type="text" placeholder="Last Name" />
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="formBEmail">
                                            <Form.Label className="fw-600 s-14">Business Email:</Form.Label>
                                            <Form.Control type="email" placeholder="Business Email" />
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="formCompany">
                                            <Form.Label className="fw-600 s-14">Company:</Form.Label>
                                            <Form.Control type="text" placeholder="Company" />
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="formJobTitle">
                                            <Form.Label className="fw-600 s-14">Job Title:</Form.Label>
                                            <Form.Control type="text" placeholder="Job title" />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label className="fw-600 s-14">Solution Interest:</Form.Label>
                                            <Form.Select >
                                                <option>Disabled select</option>
                                            </Form.Select>
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label className="fw-600 s-14">Country:</Form.Label>
                                            <Form.Select >
                                                <option>Disabled select</option>
                                            </Form.Select>
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="formJobTitle">
                                            <Form.Label className="fw-600 s-14">Phone:</Form.Label>
                                            <Form.Control type="tel" placeholder="Phone" />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Check className="fw-600 s-14" type="checkbox"
                                                label={data?.data?.sub_description}
                                            />
                                        </Form.Group>




                                        <Button className='btn-subscribe btn-sm' type="submit">
                                            Contact Us
                                        </Button>
                                    </Form>
                                </div>

                            </div>
                        </div>
                    </div>

                ) :
                    ""}

            </div>
        </>
    )
}

export default ContactUsScreen