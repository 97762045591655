import React from 'react'
import logo from "../../Assets/Images/download_1.png";


const Loader = () => {
    return (
        <>
            <div className="container">
                <div className="row align-items-center justify-content-center" style={{ height: "100vh" }} >
                    <div className="col-auto text-center">
                        <img src={logo} width="50%" alt="" />

                        <div class="loading text-center">
                            <span class="loading__dot"></span>
                            <span class="loading__dot"></span>
                            <span class="loading__dot"></span>
                        </div>

                    </div>



                </div>


            </div>


        </>
    )
}

export default Loader