import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { baseURL, webWhyUsPageView } from '../../services/api'

const initialState = {
    data: {},
    loading: false,
    // homeData: [],
    // banner: {},
    // decode: {},
    // decodeCard: [],
    // feature: {},
    // featureCards: [],
    // feedback: [],
    // manageWork: {},
    // newsletter: {}
}

export const getWhyUs = createAsyncThunk(
    "whyUs/getWhyUs",
    async () => {
        const res = await fetch(`${baseURL}${webWhyUsPageView}`, {
            // mode: 'no-cors',
            method: 'GET',
            headers: new Headers({
                'Accept': 'application/json',
                // 'Content-Type': 'application/json'
            })
        }
        )
        const resJson = await res.json()
        return resJson
    }
)

export const whyUsSlice = createSlice({
    name: 'whyUs',
    initialState,
    reducers: {},
    extraReducers: {
        [getWhyUs.pending]: (state) => {
            state.loading = true
        },
        [getWhyUs.fulfilled]: (state, { payload }) => {
            state.data = payload;
            state.loading = false

            // console.log("state.data ======  " , data) 
        },
        [getWhyUs.rejected]: (state) => {
            state.loading = false
            state.data = []
        },
    }
})

export const whyUsReducer = whyUsSlice.reducer


