import React from 'react'
import { useSelector } from 'react-redux'

const TalentMarket = () => {

    const { data } = useSelector((state => state.whyUs))

    return (
        <>
            <section className='top-theme '>

                <div className="container">
                    <div className="row p-5 justify-content-center">
                        <div className="col-md-11 ">
                            <h3 className='text-center text-white'>
                                {/* "The talent market is shifting. Organizations that aren’t
                                prepared will lose out on top talent. SeekOut helps organizations optimize external
                                sourcing and nurture their internal workforce. This gives them a distinct competitive
                                edge in talent attraction and retention." */}

                                {data?.article?.article}
                            </h3>

                            <p className='text-center mt-4 text-white'>
                                <span className='fw-700'>
                                    {/* Matthew Merker,  */}
                                    {data?.article?.author},
                                </span>
                                <span>{" "}
                                    {data?.article?.designation}
                                </span>
                                {/* Research Manager, Talent Acquisition and Strategy, IDC */}
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default TalentMarket