import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import user1 from "../../Assets/Images/user1.png";
import user2 from "../../Assets/Images/user2.png";
import user3 from "../../Assets/Images/user3.png";
import user4 from "../../Assets/Images/user4.png";

import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
const Testimonials = ({ feedback }) => {


    return (
        <>
            <section>
                <div className="container" data-aos="zoom-in" data-aos-duration="1000">
                    <div className="row my-5 pb-5">

                        <div className="col-12 mb-5 pb-5">
                            <h1 className='fw-700 text-center mb-5'>
                                {/* Happy Clients <span className='text-blue italic font-alegreya fw-700'> Feedback </span> */}
                                Happy Clients  Feedback
                            </h1>
                        </div>


                        <div className="col-12  position-relative">


                            {feedback?.map((items, index) => (
                                <div key={index} className={`position-absolute d-lg-block d-none  translate-middle 
                                ${index == 0 ? 'top-0 start-20' : ''}
                                 ${index == 1 ? 'top-50 start-100' : ''} 
                                 ${index == 2 ? 'top-50 start-0' : ''}
                                 ${index == 3 ? 'top-0 end-20' : ''}
                                 ${index == 4 ? 'top-0 end-20' : ''}
                                 ${index == 5 ? 'top-100 end-20' : ''}
                                 `}>
                                    <img src={items?.image} alt="" className='rounded-circle mb-3' width="85px" height="85px" />
                                </div>
                            ))}
                            {/* 
                            <div className='position-absolute d-lg-block d-none top-50 start-100 translate-middle' >
                                <img src={user1} alt="" className='rounded-circle mb-3' width="70px" height="70px" />
                            </div> */}

                            {/* <div className='position-absolute d-lg-block d-none top-50 start-0 translate-middle' >
                                <img src={user3} alt="" className='rounded-circle mb-3' width="70px" height="70px" />
                            </div> */}

                            {/* <div className='position-absolute d-lg-block d-none top-100 start-20 translate-middle' >
                                <img src={user4} alt="" className='rounded-circle mb-3' width="85px" height="85px" />
                            </div>

                            <div className='position-absolute d-lg-block d-none top-0 end-20 translate-middle' >
                                <img src={user3} alt="" className='rounded-circle mb-3' width="85px" height="85px" />
                            </div>

                            <div className='position-absolute d-lg-block d-none top-100 end-20 translate-middle' >
                                <img src={user4} alt="" className='rounded-circle mb-3' width="85px" height="85px" />
                            </div> */}

                            <Swiper
                                className='pb-5 my-5'
                                modules={[Navigation, Pagination, Scrollbar, A11y]}

                                spaceBetween={50}
                                slidesPerView={1}
                                onSlideChange={() => console.log('slide change')}
                                onSwiper={(swiper) => console.log(swiper)}
                                pagination={{ clickable: true }}

                            >
                                {Array.isArray(feedback) && (
                                    feedback?.map((items, index) => {
                                        return (



                                            <SwiperSlide>
                                                <div className='row justify-content-center'>
                                                    <div className="col-md-10 text-center">

                                                        <i className="fa-solid fa-quote-left s-34 text-blue mb-5" />
                                                        <p className='fw-600 s-22 text-center'>
                                                            {items?.feedback}
                                                        </p>
                                                        <img
                                                            src={items?.image}

                                                            alt="" className='rounded-circle mb-3' width="70px" height="70px" />

                                                        <p className='mb-0 fw-600'>

                                                            {items?.name}
                                                        </p>
                                                        <p className='fw-600'>
                                                            {items?.role}

                                                        </p>

                                                    </div>

                                                </div>
                                            </SwiperSlide>








                                        )
                                    })
                                )}
                            </Swiper>

                            {/* <Swiper
                                className='pb-5 my-5'
                                modules={[Navigation, Pagination, Scrollbar, A11y]}

                                spaceBetween={50}
                                slidesPerView={1}
                                onSlideChange={() => console.log('slide change')}
                                onSwiper={(swiper) => console.log(swiper)}
                                pagination={{ clickable: true }}

                            >


                                <SwiperSlide>
                                    <div className='row justify-content-center'>
                                        <div className="col-md-10 text-center">

                                            <i className="fa-solid fa-quote-left s-34 text-blue mb-5" />
                                            <p className='fw-600 s-22 text-center'>Amazing support! Totally all over my issues and we were changing it completely
                                                from being a place directory! Loving the
                                                theme on top of your task lists stay in touch with what's happening.</p>
                                            <img src={user1} alt="" className='rounded-circle mb-3' width="70px" height="70px" />

                                            <p className='mb-0 fw-600'>Jeffifer Aniston</p>
                                            <p className='fw-600'>Sales Executive</p>

                                        </div>

                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className='row justify-content-center'>
                                        <div className="col-md-10 text-center">
                                            <i className="fa-solid fa-quote-left s-34 text-blue mb-5" />

                                            <p className='fw-600 s-22 text-center'>Amazing support! Totally all over my issues and we were changing it completely
                                                from being a place directory! Loving the
                                                theme on top of your task lists stay in touch with what's happening.</p>
                                            <img src={user2} alt="" className='rounded-circle mb-3' width="70px" height="70px" />

                                            <p className='mb-0 fw-600'>Ema Alice</p>
                                            <p className='fw-600'>Product Designer</p>

                                        </div>

                                    </div>
                                </SwiperSlide>


                                <SwiperSlide>
                                    <div className='row justify-content-center'>
                                        <div className="col-md-10 text-center">
                                            <i className="fa-solid fa-quote-left s-34 text-blue mb-5" />

                                            <p className='fw-600 s-22 text-center'>Amazing support! Totally all over my issues and we were changing it completely
                                                from being a place directory! Loving the
                                                theme on top of your task lists stay in touch with what's happening.</p>
                                            <img src={user3} alt="" className='rounded-circle mb-3' width="70px" height="70px" />

                                            <p className='mb-0 fw-600'>Mikey James</p>
                                            <p className='fw-600'>Manager </p>

                                        </div>

                                    </div>
                                </SwiperSlide>


                                <SwiperSlide>
                                    <div className='row justify-content-center'>
                                        <div className="col-md-10 text-center">
                                            <i className="fa-solid fa-quote-left s-34 text-blue mb-5" />

                                            <p className='fw-600 s-22 text-center'>Amazing support! Totally all over my issues and we were changing it completely
                                                from being a place directory! Loving the
                                                theme on top of your task lists stay in touch with what's happening.</p>
                                            <img src={user4} alt="" className='rounded-circle mb-3' width="70px" height="70px" />

                                            <p className='mb-0 fw-600'>Alex bella </p>
                                            <p className='fw-600'>Developer</p>

                                        </div>

                                    </div>
                                </SwiperSlide>




                            </Swiper> */}
                        </div>

                    </div>
                </div>
            </section >
        </>
    )
}

export default Testimonials