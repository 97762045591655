import React, {useCallback, useState} from 'react';
import {baseURL} from "../services/api";

const usePost = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const postRequest = useCallback(async (url, data) => {
        setLoading(true);
        setError(null);
        try {
            const res = await fetch( baseURL + url, {
                method: 'POST',
                body: JSON.stringify(data),
                headers: new Headers({
                    accept: 'application/json',
                    'Content-Type': 'application/json'
                })
            });
            setLoading(false);
            return await res.json();
        } catch (e) {
            setLoading(false);
            setError(e);
            console.log('e', e.message);
        }
    }, []);

    return [postRequest, loading, error];
};

export default usePost;