import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { baseURL, webHomePageView } from '../../services/api'

const initialState = {
    data: {},
    loading: false,
    // homeData: [],
    // banner: {},
    // decode: {},
    // decodeCard: [],
    // feature: {},
    // featureCards: [],
    // feedback: [],
    // manageWork: {},
    // newsletter: {}
}

export const getHome = createAsyncThunk(
    "home/getHome",
    async () => {
        const res = await fetch(`${baseURL}${webHomePageView}`, {
            // mode: 'no-cors',
            method: 'GET',
            headers: new Headers({
                'Accept': 'application/json',
                // 'Content-Type': 'application/json'
            })
        }
        )
        const resJson = await res.json()
        return resJson
    }
)

export const homeSlice = createSlice({
    name: 'home',
    initialState,
    reducers: {},
    extraReducers: {
        [getHome.pending]: (state) => {
            state.loading = true
        },
        [getHome.fulfilled]: (state, { payload }) => {
            state.data = payload;
            state.loading = false

            // console.log("state.data ======  " , data) 
        },
        [getHome.rejected]: (state) => {
            state.loading = false
            state.data = []
        },
    }
})

export const homeReducer = homeSlice.reducer


