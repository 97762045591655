import React from 'react'
import blog1 from "../../Assets/Images/blog1.webp";
import blog2 from "../../Assets/Images/blog2.webp";
import blog3 from "../../Assets/Images/blog3.webp";
import Pagination from 'react-bootstrap/Pagination';
import { changePage } from '../../Redux/Slices/blogSlice';
import { useDispatch } from 'react-redux';

const BlogScreen = ({ blogs }) => {


    const dispatch = useDispatch()

    const goAhead = (url) => {
        if (url !== null) {
            dispatch(changePage(url))
        }
    }

    return (
        <>

            <div className="container py-5">
                <div className="row">

                    {Array.isArray(blogs?.data) && (
                        blogs?.data?.map((item, i) => {
                            return (
                                <>
                                    <div className="col-md-4 my-5" key={i}>
                                        <div className="card position-relative justify-content-center p-5 h-100 rounded-5 bg-light border-0">
                                            <p className='s-12 fw-500'>BLOG</p>
                                            <h5>
                                                {/* 5 Creative Solutions for Healthcare Recruiting  */}
                                                {item?.heading}
                                            </h5>

                                            <p className=''>Read Blog Post</p>

                                            <div class="position-absolute top-90 start-90 translate-middle">
                                                <img src={item?.image} width="100px" height="100px" className='rounded-circle' alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        })
                    )}

                    <div className="col-md-12 text-center">

                        <Pagination className='justify-content-center'>
                            {
                                Array.isArray(blogs?.links) && (
                                    blogs?.links?.map((item) => {
                                        if (item?.label == "&laquo; Previous") {
                                            return <Pagination.Prev onClick={() => { goAhead(item?.url) }} />
                                        } else if (item?.label == "Next &raquo;") {
                                            return <Pagination.Next onClick={() => { goAhead(item?.url) }} />
                                        } else {
                                            return <Pagination.Item active={item?.active} onClick={() => { goAhead(item?.url) }} >{item?.label}</Pagination.Item>
                                        }
                                    })
                                )
                            }
                            {/* <Pagination.Ellipsis /> */}
                        </Pagination>

                    </div>

                    {/* 
                    <div className="col-md-4">

                        <div className="card p-4 position-relative justify-content-center h-100 rounded-5 bg-light border-0">

                            <p className='s-12 fw-500'>BLOG</p>
                            <h5>AdventHealth's Cathy Henesey on Building a Metric-Driven HR Team</h5>

                            <p >Read Blog Post</p>

                            <div class="position-absolute top-90 start-90 translate-middle">
                                <img src={blog2} width="100px" height="100px" className='rounded-circle' alt="" />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">


                        <div className="card position-relative justify-content-center p-4 h-100 rounded-5 bg-light border-0">
                            <p className='s-12 fw-500'>BLOG</p>
                            <h5 className='text-dark'>An Important Message from iBroker: Sharing Our Commitment to Responsible & Ethical AI Usage </h5>

                            <p className=''>Read Blog Post</p>



                            <div class="position-absolute top-90 start-90 translate-middle">
                                <img src={blog3} width="100px" height="100px" className='rounded-circle' alt="" />
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </>
    )
}

export default BlogScreen