import React, { useState } from 'react'
import newsletter from "../../Assets/Images/newsletter-img.png";
import "./Home.css"
import { insertNewletter } from '../../services/api'
import usePost from '../../hooks/usePost';
import Swal from 'sweetalert2'


const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 1500,
    timerProgressBar: true,
    customClass: {
        container: 'zIndex', // Add your custom class name here
    },
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)

    },
})



const Newsletter = ({ newsletter }) => {

    const [insertNewletterRequest, loading, error] = usePost()

    const handleSubmit = async () => {

        const data = {
            email: email
        }
        const res = await insertNewletterRequest(`${insertNewletter}`, data)
        console.log('result', res);

        if (res?.status === 'success') {
            // window.open(`${res?.msg}`, '_blank');
            // alert(res?.msg)
            Toast.fire({
                icon: 'success',
                title: res?.message
            })
            setEmail("")
        }
    }




    const [email, setEmail] = useState("")

    // const submit = () => {
    //     console.log("emailemail", email)
    // }
    return (
        <>
            <div className="newsletter">
                <div className="container">

                    {newsletter ? (
                        <div className="row align-items-center">

                            <div className="col-md-6 p-5">
                                <div className=''>
                                    <img
                                        src={newsletter?.image}
                                        className="pulse" width="100%" alt="" />
                                </div>
                            </div>

                            <div className="col-md-6 p-5" data-aos="flip-left"
                                data-aos-easing="ease-out-cubic"
                                data-aos-duration="2000">
                                <h1 className='fw-700 text-white'>
                                    {/* Subscribe to our <span className='text-white italic'> Newsletter</span> */}
                                    {newsletter?.title}
                                </h1>

                                <p className='mt-3 s-20 text-white'>

                                    {newsletter?.description}

                                    {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                    Duis finibus mi id elit gravida, quis tincidunt purus fringilla. */}
                                </p>

                                <div className="row bg-white newsletter-input p-1  rounded">
                                    <div className="col">
                                        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className='w-100 border-0 py-3 field-input' placeholder='Enter Email' />
                                    </div>
                                    <div className="col-auto p-0">
                                        <button onClick={handleSubmit} className='btn-theme py-3'>
                                            {
                                                loading ? "loading..." :
                                                    `${ newsletter?.button_title}`
                                            }

                                            {/* Subscribe */}
                                        </button>
                                    </div>
                                </div>
                            </div>


                        </div>
                    ) : ''}

                </div>
            </div>
        </>
    )
}

export default Newsletter