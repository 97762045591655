import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { homeReducer } from "./Slices/homeSlice";
import { userReducer } from "./Slices/userSlice";
import { whyUsReducer } from "./Slices/whyUsSlice";
import { blogReducer } from "./Slices/blogSlice";
import { eventsReducer } from "./Slices/eventSlice";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["home", "whyUs", "blog", "event"],
};

const rootReducer = combineReducers({
  home: homeReducer,
  whyUs: whyUsReducer,
  blog: blogReducer,
  event: eventsReducer,
  user: userReducer,
});
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
