import React from 'react'
import PricingPlaneScreen from '../Components/Pricing/PricingPlaneScreen'
import ScrollToTop from '../Components/ScrollToTop'

const PricingPlane = () => {
  return (
    <>
    <ScrollToTop />
    <PricingPlaneScreen />
    </>
  )
}

export default PricingPlane