import React from 'react'

const EventsBanner = ({ banner }) => {
    return (
        <>
            <div className="newsletter " style={{marginTop:"5rem"}}>
                <div className="container">
                    <div className="row py-5">
                        <div className="col-md-12">
                            <br />
                            <br />
                            <h1 className='text-white text-center'>
                                {/* Upcoming Events & Webinars */}
                                {banner?.heading}
                            </h1>
                            <p className='text-center text-white fw-600'>
                                {/* Explore upcoming events and webinars and register for
                                informative sessions from Ibroker, our partners and other
                                industry leaders. Check back often as we continue to add events
                                and webinars throughout the year */}

                                {banner?.description}

                            </p>
                            <br />
                            <br />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EventsBanner