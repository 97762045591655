import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useForm } from "react-hook-form";
import { paymentCharges, post } from "../../services/api";
import usePost from "../../hooks/usePost";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";


const SignUpScreen = () => {
    const { id } = useParams()
    const [showPass, setShowPass] = useState(false)

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setError,
        setValue,
    } = useForm()

    const [signUpRequest, loading, error] = usePost()

    const onSubmit = async (data) => {
        data.name = data.firstName + ' ' + data.lastName
        data.package_id = id
        delete data.gender
        delete data.firstName
        delete data.lastName

        const res = await signUpRequest(paymentCharges, data)
        if (res?.status === 'success') {
            window.open(`${res?.msg}`, '_blank');
            reset()
        }
        res?.errors && Object.keys(res?.errors).map((key) => {
            res?.errors[key].map((value) => {
                setError(`${key}`, { type: "manual", message: `${value}` })
            })
        })
    }
    return (
        <>
            <section className='top-banner signup' style={{ marginTop: "5rem" }}>
                <div className="container">
                    <div className="row py-5 mb-5 justify-content-center">
                        <div className="col-lg-7">
                            <div className="card p-md-5 p-3 rounded-5 border-0">
                                <h3 className='text-center text-blue fw-700'>Signup </h3>
                                <h6 className='text-center fw-600'>Easily Create Your Account
                                </h6>
                                <Form onSubmit={handleSubmit(onSubmit)} className='pt-4 row'>
                                    <Form.Group className="mb-3 col-md-6">
                                        <Form.Label className='fw-600 s-14'>First Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            className='py-3'
                                            placeholder="Enter Your First Name"
                                            {...register("firstName", { required: 'First Name is required' })}
                                        />
                                        {errors.firstName && (
                                            <Form.Text className="text-danger">
                                                {errors.firstName.message}
                                            </Form.Text>
                                        )}
                                    </Form.Group>

                                    <Form.Group className="mb-3 col-6">
                                        <Form.Label className='fw-600 s-14'>Last Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            className='py-3'
                                            placeholder="Enter Your Last Name"
                                            {...register("lastName", { required: 'Last Name is required' })}
                                        />


                                        {errors.lastName && (
                                            <Form.Text className="text-danger">
                                                {errors.lastName.message}
                                            </Form.Text>
                                        )}
                                    </Form.Group>

                                    <Form.Group className="mb-3 col-6">
                                        <Form.Label className='fw-600 s-14'>Gender</Form.Label>
                                        <Form.Select
                                            className='py-3'
                                            {...register("gender", {
                                                required: 'Gender is required'
                                            })}
                                        >
                                            <option value=""> select option</option>
                                            <option value="male"> Male</option>
                                            <option value="female"> Female</option>
                                        </Form.Select>
                                        {errors.gender && (
                                            <Form.Text className="text-danger">
                                                {errors.gender.message}
                                            </Form.Text>
                                        )}
                                    </Form.Group>

                                    <Form.Group className="mb-3 col-6">
                                        <Form.Label className='fw-600 s-14'>Email</Form.Label>
                                        <Form.Control
                                            type="email"
                                            className='py-3'
                                            placeholder="Enter Your Email"
                                            {...register("email", {
                                                required: 'Email is required',
                                                pattern: {
                                                    value: /^\S+@\S+$/i,
                                                    message: 'Invalid Email',
                                                }
                                            })}
                                        />
                                        {errors.email && (
                                            <Form.Text className="text-danger">
                                                {errors.email.message}
                                            </Form.Text>
                                        )}
                                    </Form.Group>

                                    <Form.Group className="mb-3 col-6">
                                        <Form.Label className='fw-600 s-14'>Phone No</Form.Label>
                                        <Form.Control
                                            type="tel"
                                            className='py-3'
                                            placeholder="Enter Your Phone No"
                                            {...register("phone", {
                                                required: 'Phone No is required',
                                            })}
                                        />
                                        {errors.phone && (
                                            <Form.Text className="text-danger">
                                                {errors.phone.message}
                                            </Form.Text>
                                        )}
                                    </Form.Group>

                                    <Form.Group className="mb-3 col-6">
                                        <Form.Label className='fw-600 s-14'>Password</Form.Label>
                                        <div className=' row mx-1 border rounded align-items-center'>
                                            <div className="col ">
                                                <input

                                                    type={!showPass ? "password" : "text"}
                                                    className='w-100  py-3 border-0'
                                                    placeholder="Enter Your Password"
                                                    {...register("password", { required: 'Password is required' })}
                                                />
                                            </div>
                                            <div className="col-auto">
                                                {
                                                    showPass ?
                                                        <FaRegEyeSlash onClick={() => setShowPass(false)} />
                                                        :
                                                        <FaRegEye onClick={() => setShowPass(true)} />

                                                }

                                            </div>


                                        </div>
                                        {errors.password && (
                                            <Form.Text className="text-danger">
                                                {errors.password.message}
                                            </Form.Text>
                                        )}
                                    </Form.Group>

                                    <div className="col-md-12">
                                        <Button className='w-100 btn-subscribe mb-3' type="submit">
                                            {loading ? '...' : 'Sign Up'}
                                        </Button>
                                    </div>

                                    <div className="mb-3 col-md-12 text-center fw-600 s-14" >
                                        Already have an account
                                        <Link to="/sign-in" className='ps-2'>Login</Link>
                                    </div>

                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SignUpScreen