import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

const Whyus = ({ feature, featureCards }) => {

    return (
        <>
            <div className=" bg-whyUs mt-5">
                <div className="why-us" >


                    {feature ? (
                        <div className="container">
                            <div className="row justify-content-center" >
                                <div className="col-8">
                                    <h1 className='text-center mt-5 fw-700 text-white'>
                                        {feature?.title}
                                        {/* We offer Powerfull features */}
                                    </h1>
                                    <p className='text-center text-white s-20 mt-4'>
                                        {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                         Duis finibus mi id elit gravida, quis tincidunt purus fringilla.
                                          Aenean convallis a neque non pellentesque. */}
                                        {feature?.description}

                                        <br />
                                        <br />
                                        <br />


                                    </p>
                                </div>
                            </div>
                        </div>
                    ) : ""}

                </div>

                <div className="container" >
                    <div className="row py-5">

                        {
                            featureCards?.length > 0 && (
                                featureCards?.map((item, index) => {
                                    return (
                                        <div key={index} className="col-md-4 mt-3">
                                            <Link>
                                                <div className="card bg-card text-white border-0 p-4">
                                                    <div className="row ">

                                                        <div className="col-auto">
                                                            <i className="fa-solid fa-circle-dot s-22 pt-1 "></i>
                                                        </div>

                                                        <div className="col">

                                                            <h4 className="fw-700">
                                                                {item?.title}
                                                            </h4>
                                                            <p>
                                                                {item?.description}

                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    )
                                })
                            )
                        }



                        {/* <div className="col-md-4 mt-3">
                            <Link>
                                <div className="card bg-card text-white border-0 p-4">
                                    <div className="row ">

                                        <div className="col-auto">
                                            <i className="fa-solid fa-circle-dot s-22 pt-1 "></i>
                                        </div>

                                        <div className="col">

                                            <h4 className="fw-700">Time Billing</h4>
                                            <p>Pellentesque at libero sed tellus fringilla volutpat. Nullam vulputate velit id augue commodo scelerisque.</p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className="col-md-4 mt-3">
                            <Link>
                                <div className="card bg-card text-white border-0 p-4">
                                    <div className="row ">

                                        <div className="col-auto">
                                            <i className="fa-solid fa-circle-dot s-22 pt-1 "></i>
                                        </div>

                                        <div className="col">

                                            <h4 className="fw-700">Email Tasks in</h4>
                                            <p>Pellentesque at libero sed tellus fringilla volutpat. Nullam vulputate velit id augue commodo scelerisque.</p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className="col-md-4 mt-3">
                            <Link>
                                <div className="card bg-card text-white border-0 p-4">
                                    <div className="row ">

                                        <div className="col-auto">
                                            <i className="fa-solid fa-circle-dot s-22 pt-1 "></i>
                                        </div>

                                        <div className="col">

                                            <h4 className="fw-700">Calendar Sync</h4>
                                            <p>Pellentesque at libero sed tellus fringilla volutpat. Nullam vulputate velit id augue commodo scelerisque.</p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className="col-md-4 mt-3">
                            <Link>
                                <div className="card bg-card text-white border-0 p-4">
                                    <div className="row ">

                                        <div className="col-auto">
                                            <i className="fa-solid fa-circle-dot s-22 pt-1 "></i>
                                        </div>

                                        <div className="col">

                                            <h4 className="fw-700">Mobile Application</h4>
                                            <p>Pellentesque at libero sed tellus fringilla volutpat. Nullam vulputate velit id augue commodo scelerisque.</p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className="col-md-4 mt-3">
                            <Link>
                                <div className="card bg-card text-white border-0 p-4">
                                    <div className="row ">

                                        <div className="col-auto">
                                            <i className="fa-solid fa-circle-dot s-22 pt-1 "></i>
                                        </div>

                                        <div className="col">

                                            <h4 className="fw-700">Access Control</h4>
                                            <p>Pellentesque at libero sed tellus fringilla volutpat. Nullam vulputate velit id augue commodo scelerisque.</p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className="col-md-4 mt-3">
                            <Link>
                                <div className="card bg-card text-white border-0 p-4">
                                    <div className="row ">

                                        <div className="col-auto">
                                            <i className="fa-solid fa-circle-dot s-22 pt-1 "></i>
                                        </div>

                                        <div className="col">

                                            <h4 className="fw-700">Out of Office</h4>
                                            <p>Pellentesque at libero sed tellus fringilla volutpat. Nullam vulputate velit id augue commodo scelerisque.</p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className="col-md-4 mt-3">
                            <Link>
                                <div className="card bg-card text-white border-0 p-4">
                                    <div className="row ">

                                        <div className="col-auto">
                                            <i className="fa-solid fa-circle-dot s-22 pt-1 "></i>
                                        </div>

                                        <div className="col">

                                            <h4 className="fw-700">Snooze Tasks</h4>
                                            <p>Pellentesque at libero sed tellus fringilla volutpat. Nullam vulputate velit id augue commodo scelerisque.</p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className="col-md-4 mt-3">
                            <Link>
                                <div className="card bg-card text-white border-0 p-4">
                                    <div className="row ">

                                        <div className="col-auto">
                                            <i className="fa-solid fa-circle-dot s-22 pt-1 "></i>
                                        </div>

                                        <div className="col">

                                            <h4 className="fw-700">Track Conversations</h4>
                                            <p>Pellentesque at libero sed tellus fringilla volutpat. Nullam vulputate velit id augue commodo scelerisque.</p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div> */}


                    </div>
                </div>
            </div>
        </>
    )
}

export default Whyus