import React from 'react'
import { useSelector } from 'react-redux'

const ProvenPartners = () => {

    const { data } = useSelector((state => state.whyUs))

    return (
        <>
            <div className="container">
                <div className="row py-5">
                    <div className="col-12">
                        <h1 className='fw-600 text-center'>
                            {data?.trusted_partner?.heading}
                            {/* Trusted and proven partner */}
                        </h1>

                        <p className='text-center'>
                            {data?.trusted_partner?.description}

                            {/* SeekOut is trusted by leaders in a variety of roles from 1000+ customers, from human resources and recruiting to talent research. Hear how customers rank us on review sites, G2 and TrustRadius. */}
                        </p>
                    </div>

                    <div className="row">

                        {Array.isArray(data?.truested_card) ? (
                            data?.truested_card?.map((item, i) => {
                                return (
                                    <div className="col-md-4">
                                        <div className="card rounded-4 p-4 light-gradient text-center h-100 border-0">


                                            <h5>
                                                {/* D&I Sourcer */}
                                                {item?.heading}
                                            </h5>

                                            <p className='s-18 '>
                                                {/* “The ability to slice & dice talent pools into diverse subsets
                                                is very helpful in building out a diverse slate of candidates.” */}
                                                {item?.description}
                                            </p>
                                        </div>
                                    </div>
                                )
                            })
                        ) : "No data Found"}



                       

                    </div>


                    <div className="col-12 text-center mt-5">
                        <button className="btn-theme rounded">
                            {data?.trusted_partner?.button}
                        </button>
                    </div>
                </div>
            </div>

        </>
    )
}

export default ProvenPartners