import React, { useEffect, useState } from 'react'
import bar from "../../Assets/Images/Barchart.png";
import abs from "../../Assets/Images/Abstract.png";
import pie from "../../Assets/Images/Pie_Chart.png";
import Headshot from "../../Assets/Images/Headshot.png";
import { useSelector } from 'react-redux';

const Oneplatform = () => {

    const { data } = useSelector((state => state.whyUs))

    // console.log("card =========", card)



    return (
        <>
            <div className="container">
                <div className="row py-5">
                    <div className="col-12">
                        <h1 className='text-center fw-600 mb-3'>
                            {/* One platform for your entire talent strategy  */}
                            {data?.platform?.heading}
                        </h1>

                        <p className='text-center fw-500'>
                            {/* Most talent platforms solve for a singular problem. iBroker brings talent acquisition,
                            talent management, and talent analytics into one unified platform. Maximize your
                            ROI and create a holistic strategy across internal and external talent. */}
                            {data?.platform?.description}

                        </p>
                    </div>

                </div>

                <div className="row justify-content-around">

                    {Array.isArray(data?.platform_card) ?
                        (
                            data?.platform_card?.map((item, i) => {
                                return (
                                    <div className="col-md-5" key={i}>
                                        <div className="card light-gradient rounded-4 border-0 p-5 h-100 align-items-center justify-content-center">
                                            <img src={item?.image} width="100px" height="100px" alt="" />

                                            <h5 className='fw-600 text-center mt-5'>
                                                {/* Improve efficiency with human-centered AI   */}
                                                {item?.heading}
                                            </h5>

                                            <p className='fw-500 text-center'>
                                                {/* AI should assist, not replace human decision making. iBroker puts
                                         humans at the center of our user experience, using AI to surface insights a
                                         nd improve efficiency. iBroker prioritizes AI compliance, mitigating bias across
                                          your HR and recruiting processes.  */}
                                                {item?.description}
                                            </p>

                                        </div>
                                    </div>
                                )

                            })
                        )
                        : "No Data Found"}


                    {/* <div className="col-md-5">
                        <div className="card light-gradient rounded-4 border-0 p-5 h-100 align-items-center justify-content-center">
                            <img src={pie} width="100px" height="100px" alt="" />

                            <h5 className='fw-600 text-center mt-5'>Get data-driven insights across systems </h5>

                            <p className='fw-500 text-center'>You can’t understand your people without data, but talent data is scattered across many systems. iBroker automatically pulls data from internal and external sources—with no manual data entry. Get insights that drive reskilling, redeployment, and recruiting priorities. </p>

                        </div>
                    </div>

                    <div className="col-md-5">
                        <div className="card light-gradient rounded-4 border-0 p-5 h-100 align-items-center justify-content-center">
                            <img src={Headshot} width="100px" height="100px" alt="" />

                            <h5 className='fw-600 text-center mt-5'>Find specialized & underrepresented talent   </h5>

                            <p className='fw-500 text-center'>iBroker specializes in hard-to-find talent, with dedicated technical, healthcare, and cleared talent pools. iBroker also provides critical insights around diversity within your talent pipeline, helping you reduce unconscious bias and find underrepresented candidates. </p>

                        </div>
                    </div>

                    <div className="col-md-5">
                        <div className="card light-gradient rounded-4 border-0 p-5 h-100 align-items-center justify-content-center">
                            <img src={bar} width="100px" height="100px" alt="" />

                            <h5 className='fw-600 text-center mt-5'>Unleash potential beyond skills </h5>

                            <p className='fw-500 text-center'>Skills are critical, but they don’t fully represent a person’s potential. iBroker surfaces comprehensive talent insights—including skills, capabilities, experience, and aspirations—to connect people to opportunities. Employees can chart their own career paths in a self-service talent marketplace.  </p>

                        </div>
                    </div> */}
                </div>
            </div>
        </>
    )
}

export default Oneplatform